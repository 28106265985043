import React from 'react';
import styled from 'styled-components';

const PlusOrMinusIcon = styled.i`
  font-size: 16px;
  color: ${props => 'black'};
  cursor: pointer;
`;

const PlusOrMinus = props => (
  <PlusOrMinusIcon className={`fal fa-${props.plusOrMinus}`} {...props} />
);

export const PlusIcon = props => <PlusOrMinus plusOrMinus="plus" {...props} />;

export const MinusIcon = props => (
  <PlusOrMinus plusOrMinus="minus" {...props} />
);
