import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Select } from 'merchant-portal-components';
import { setDefaultAppFeed } from '../../appFeedLayoutActions';

const Wrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  padding: 24px;
  margin-bottom: 24px;
  font-size: 15px;
  line-height: 1.67;
  color: #191818;
`;

const DefaultLayoutTitle = styled.p`
  margin-top: 24px;
  margin-bottom: 8px;
`;

const StarIcon = styled.i`
  color: #f3c500;
  font-size: 16px;
  margin-right: 10px;
`;

const SelectDefaultFeed = styled.div`
  width: 300px;
`;

const SelectDefault = ({ layoutPage, setDefaultAppFeed }) => {
  const list = layoutPage.listAppFeed;

  const defaultTemplate = list.filter(
    (template) => template.is_default === true
  );

  const handleSelect = (value) => {
    const selectedTemplate = list.filter(
      (singleFeed) => singleFeed.id === value.id
    );
    setDefaultAppFeed(selectedTemplate[0]);
  };

  return (
    <Wrapper>
      <p>
        App Layout is the feed the user sees in their app, you can assign set a
        default layout to display for all businesses or specify a business you
        want to assign a layout to.
      </p>
      <DefaultLayoutTitle>
        <StarIcon className="fas fa-star"></StarIcon> Default Layout
      </DefaultLayoutTitle>

      <SelectDefaultFeed>
        <Select
          pagination
          small
          placeholder="Select Default Layout"
          options={list}
          getOptionValue={(template) => template.id}
          getOptionLabel={(template) => template.template_name}
          value={defaultTemplate}
          onChange={(option) => handleSelect(option)}
        />
      </SelectDefaultFeed>
    </Wrapper>
  );
};

export default connect(null, { setDefaultAppFeed })(SelectDefault);
