import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from 'merchant-portal-components';
import { connect } from 'react-redux';
import * as actions from './actions';

const PasswordRequirements = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-left: 3px;
  margin-top: 5px;

  & > span {
    font-size: 12px;
    font-family: 'Montserrat';
    color: #959595;
    letter-spacing: -0.02px;
    margin-bottom: 8px;
  }
`;

const RegexTextSpan = styled.span`
  color: ${(props) => (props.isValid ? '#127428 !important' : 'inherit')};
`;

function PasswordVerifier(props) {
  const { password, passwordConfirm } = props;

  useEffect(() => {
    Promise.all([
      props.passwordHasEightDigits(password),
      props.passwordHasUpperAndLowerCharacter(password),
      props.passwordHasDigitOrSymbol(password),
      props.passwordMatch(password, passwordConfirm),
    ]);
  }, [password, passwordConfirm]);

  return (
    <PasswordRequirements>
      <RegexTextSpan isValid={props.passwordVerifier.eight}>
        Minimum 8 characters{' '}
        {props.passwordVerifier.eight && <Icon className="fal fa-check" />}
      </RegexTextSpan>

      <RegexTextSpan isValid={props.passwordVerifier.upperLower}>
        At least 1 uppercase and 1 lowercase character{' '}
        {props.passwordVerifier.upperLower && <Icon className="fal fa-check" />}
      </RegexTextSpan>

      <RegexTextSpan isValid={props.passwordVerifier.digitSymbol}>
        At least 1 digit or symbol{' '}
        {props.passwordVerifier.digitSymbol && (
          <Icon className="fal fa-check" />
        )}
      </RegexTextSpan>
      <RegexTextSpan isValid={props.passwordVerifier.typedPasswordsMatch}>
        Confirmation password match{' '}
        {props.passwordVerifier.typedPasswordsMatch && (
          <Icon className="fal fa-check" />
        )}
      </RegexTextSpan>
    </PasswordRequirements>
  );
}

const mapStateToProps = (state) => ({
  passwordVerifier: state.passwordVerifierReducer,
});

export default connect(mapStateToProps, { ...actions })(PasswordVerifier);
