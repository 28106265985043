import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { SmallToggleSwitch } from '../../../../../components/ToggleSwitch/ToggleSwitch';
import { RenderSelect } from '../../../../../components/FormElements/FormikElements';
import { Field } from 'formik';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
  align-items: center;
`;

const LocationSelection = ({ setFieldValue, allBusinesses, businesses }) => {
  const [isChecked, setIsChecked] = useState(!businesses.length);
  return (
    <Wrapper>
      <SmallToggleSwitch
        checked={isChecked}
        label="All Locations"
        onChange={e => {
          setFieldValue('businesses', []);
          setIsChecked(e.target.checked);
        }}
      />
      {!isChecked && (
        <Field
          name="businesses"
          component={RenderSelect}
          options={allBusinesses}
          placeholder="Locations"
          isMulti
          options={allBusinesses ? allBusinesses : []}
          getOptionValue={option => option}
          getOptionLabel={({ display_name }) => display_name}
          simpleValue
          valueKey="id"
        />
      )}
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  allBusinesses: state.businessLocationsList.businessLocationsOfMerchant
});

export default connect(mapStateToProps)(LocationSelection);
