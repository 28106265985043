import styled from 'styled-components';

export const MainDiv = styled.div`
  margin-top: 36px;
`;

export const PaginationWrapper = styled.div`
  padding-top: 135px;
  padding-bottom: 33px;
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
`;

export const DiscountDiv = styled.div``;

export const DiscountNameCell = styled.div`
  height: 80px;
  margin-top: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
`;

export const POSDiv = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const DiscountHeader = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #191818;
  height: 25px;
`;

export const POSHeader = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #2440ae;
  height: 25px;
`;

export const POSFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.fieldsNumber}, auto)`};
`;

export const POSValueCell = styled.div`
  height: 80px;
  margin-top: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding: 0 10px 15px 0;
`;

export const LineDivider = styled.hr`
  margin-top: 15px;
  border-color: rgba(0, 0, 0, 0.1);
  grid-column: span ${props => `${props.fieldsNumber}`};
`;

export const ToogleFieldPOSWrapper = styled.div`
  margin-top: 10px;
  height: 100%;
`;

export const SelectDiv = styled.div`
  margin-top: 16px;
  margin-bottom: 50px;
`;
