import React from 'react';
import styled from 'styled-components';
import SizeDrawer from './SizeDrawer';
import OptionDrawer from './OptionDrawer';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  margin-top: 16px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px 64px;
  }
`;

const SubOptionWrapper = styled.div`
  background-color: #eeedf1;
  padding: 16px;
  grid-column: 1 / -1;
`;

const Drawers = ({flatProduct}) => {
  return (
    <Wrapper>
      <SizeDrawer />
      {flatProduct.options &&
        flatProduct.options.map(optionId => {
          const option = flatProduct.optionsById[optionId];
          if (!option.sub_options.length) {
            return (
              <OptionDrawer
                flatProduct={flatProduct}
                option={option}
                key={option.option_id}
                id={option.option_id}
              />
            );
          } else {
            return (
              <SubOptionWrapper key={option.option_id}>
                {option.name}
                {option.sub_options.map(subOptionId => {
                  const subOption = flatProduct.subOptionsById[subOptionId];
                  return (
                    <OptionDrawer
                      flatProduct={flatProduct}
                      option={subOption}
                      key={subOption.option_id}
                    />
                  );
                })}
              </SubOptionWrapper>
            );
          }
        })}
    </Wrapper>
  );
};

export default Drawers;
