import styled from 'styled-components';

const CardButton = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: ${props => props.theme.white};
  margin-left: auto;
  margin-bottom: 4px;
`;

export default CardButton;
