import React from 'react';
import { Modal } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';
import styled from 'styled-components';

const MainDiv = styled.div`
  width: 550px;
  height: 237px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
`;

const ModalHeader = styled.div`
  display: grid;
  grid-template-columns: 7fr 1fr;
  text-align: left;
  margin-bottom: 16px;
`;

const Title = styled.p`
  font-size: 20px;
  letter-spacing: 0.03px;
  color: #191818;
`;

const ModalBody = styled.p`
  margin-bottom: 48px;
  line-height: 1.5;
  letter-spacing: 0.11px;
  color: #191818;
  font-size: 14px;
`;

const ButtonRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
`;

const ConfirmUnsavedChangesModal = ({ toggleConfirmModal, handleSave }) => {
  return (
    <Modal borderRadius>
      <MainDiv>
        <ModalHeader>
          <Title>Unsaved Changes</Title>
          <div onClick={toggleConfirmModal} style={{ textAlign: 'right' }}>
            <i className="fal fa-times"></i>
          </div>
        </ModalHeader>
        <ModalBody>
          Please save your changes before you proceed or your progress will be
          lost
        </ModalBody>

        <ButtonRow>
          <Button
            secondary
            type="button"
            style={{ textAlign: 'left' }}
            onClick={toggleConfirmModal}
          >
            Cancel
          </Button>
          <Button type="button" onClick={handleSave}>
            Save and Continue
          </Button>
        </ButtonRow>
      </MainDiv>
    </Modal>
  );
};

export default ConfirmUnsavedChangesModal;
