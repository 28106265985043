export const GET_ORDER_DETAILS_BY_ORDER_ID_REQUEST =
  'GET_ORDER_DETAILS_BY_ORDER_ID_REQUEST';
export const GET_ORDER_DETAILS_BY_ORDER_ID_SUCCESS =
  'GET_ORDER_DETAILS_BY_ORDER_ID_SUCCESS';
export const CANCEL_ORDER_DETAILS_BY_ORDER_ID_SUCCESS =
  'CANCEL_ORDER_DETAILS_BY_ORDER_ID_SUCCESS';
export const CANCEL_ORDER_DETAILS_BY_ORDER_ID_REQUEST =
  'CANCEL_ORDER_DETAILS_BY_ORDER_ID_REQUEST';
export const TOGGLE_CANCEL_ORDER_MODAL_VISIBILITY =
  'TOGGLE_CANCEL_ORDER_MODAL_VISIBILITY';
export const GET_ORDER_DETAILS_BY_ORDER_ID_FAILURE =
  'GET_ORDER_DETAILS_BY_ORDER_ID_FAILURE';
export const TOGGLE_SEND_LOYALTY_MODAL_VISIBILITY =
  'TOGGLE_SEND_LOYALTY_MODAL_VISIBILITY';
export const CANCEL_ORDER_DETAILS_BY_ORDER_ID_FAILURE =
  'CANCEL_ORDER_DETAILS_BY_ORDER_ID_FAILURE';
export const SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_REQUEST =
  'SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_REQUEST';
export const SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_SUCCESS =
  'SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_SUCCESS';
export const SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_FAILURE =
  'SEND_LOYALTY_POINTS_BY_CUSTOMER_ID_FAILURE';
export const RESET_ORDER_DETAILS_REDUCER_STATE =
  'RESET_ORDER_DETAILS_REDUCER_STATE';
