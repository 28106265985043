import {
  GET_APP_DETAIL_CONFIG_SCHEMA,
  GET_APP_DETAIL,
  UPDATE_APP_DETAIL_CONFIG,
  INITIATE_GET_APP_DETAILS
} from './AppDetailTypes';

const initialState = {
  appDetailSchema: [],
  appDetails: {},
  requestingAppDetails: false,
  appDetailPatchSchema: {
    feed_style_types: [
      'list_card',
      'horizontal_cards',
      'single_compact_card',
      'single_card'
    ],
    integrations: ['email'],
    google_tags: [
      'analytics_tracking_code', 
      'checkout_conversion_id'
    ]
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_APP_DETAIL_CONFIG_SCHEMA:
      return {
        ...state,
        appDetailSchema: action.schema,
        requestingAppDetails: true
      };
    case GET_APP_DETAIL:
      return {
        ...state,
        appDetails: action.appDetails,
        requestingAppDetails: true
      };
    case UPDATE_APP_DETAIL_CONFIG:
      return {
        ...state,
        appDetails: {
          ...state.appDetails,
          [action.appDetail.platform_name]: action.appDetail
        },
        requestingAppDetails: true
      };
    case INITIATE_GET_APP_DETAILS:
      return {
        ...state,
        requestingAppDetails: action.requestingAppDetails
      };
    default:
      return state;
  }
}
