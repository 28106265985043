import api from '../../../../api';
import { createAlert } from '../../../Alert/actions';

import {
  GET_MOBILE_MESSAGES_REQUEST,
  GET_MOBILE_MESSAGES_SUCCESS,
  GET_MOBILE_MESSAGES_FAILURE,
  INITIATE_CREATE_MOBILE_MESSAGE,
  CREATE_MOBILE_MESSAGE_REQUEST,
  CREATE_MOBILE_MESSAGE_SUCCESS,
  CREATE_MOBILE_MESSAGE_FAILURE,
  INITIATE_EDIT_MOBILE_MESSAGE,
  EDIT_MOBILE_MESSAGE_REQUEST,
  EDIT_MOBILE_MESSAGE_SUCCESS,
  EDIT_MOBILE_MESSAGE_FAILURE,
  CANCEL_MOBILE_MESSAGE,
  CREATE_TEST_MESSAGE_REQUEST,
  CREATE_TEST_MESSAGE_FAILURE,
  CREATE_TEST_MESSAGE_SUCCESS,
  RESET_TEST_MESSAGES,
  READ_ONLY_MOBILE_MESSAGE,
  UPDATE_MOBILE_MESSAGES_REQUEST_PARAMS,
  UPDATE_SORTED_MESSAGES_LIST
} from './mobileMessagesTypes';

export function fetchMobileMessages() {
  return function(dispatch) {
    dispatch({ type: GET_MOBILE_MESSAGES_REQUEST });
    return api.get(`/message-blasts?include=stats`).then(
      response => {
        dispatch({
          type: GET_MOBILE_MESSAGES_SUCCESS,
          mobileMessages: response.data
        });
      },
      error => {
        dispatch({
          type: GET_MOBILE_MESSAGES_FAILURE,
          error
        });
      }
    );
  };
}

export function createMobileMessage(mobileMessage) {
  return function(dispatch) {
    dispatch({ type: CREATE_MOBILE_MESSAGE_REQUEST });
    return api.post(`/message-blasts`, mobileMessage).then(
      response => {
        dispatch(fetchMobileMessages());
        dispatch(
          createAlert({
            type: 'success',
            message: 'Mobile Message successfully created'
          })
        );
      },
      error => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred trying to create Mobile Message'
          })
        );
        dispatch({
          type: CREATE_MOBILE_MESSAGE_FAILURE,
          error
        });
      }
    );
  };
}

export function initiateCreateMobileMessage() {
  return function(dispatch) {
    dispatch({ type: INITIATE_CREATE_MOBILE_MESSAGE });
  };
}
export function readOnlyMobileMessage(mobileMessage) {
  return {
    type: READ_ONLY_MOBILE_MESSAGE,
    payload: mobileMessage
  };
}
export function editMobileMessage(mobileMessage, messageId) {
  return function(dispatch) {
    dispatch({ type: EDIT_MOBILE_MESSAGE_REQUEST });
    api.patch(`/message-blasts/${messageId}`, mobileMessage).then(
      response => {
        dispatch(fetchMobileMessages());
        dispatch(
          createAlert({
            type: 'success',
            message: 'Mobile Message successfully edited'
          })
        );
      },
      error => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred trying to update Mobile Message'
          })
        );
      }
    );
  };
}

export function cancelMobileMessage() {
  return { type: CANCEL_MOBILE_MESSAGE };
}

export function initiateEditMobileMessage(mobileMessage) {
  return { type: INITIATE_EDIT_MOBILE_MESSAGE, payload: mobileMessage };
}

export function updateMessagesRequestParams(requestParams) {
  return function(dispatch) {
    dispatch({
      type: 'UPDATE_MOBILE_MESSAGES_REQUEST_PARAMS',
      payload: requestParams
    });
  };
}

export function updateSortedMessagesList(messagesList, sortOrder) {
  return {
    type: 'UPDATE_SORTED_MESSAGES_LIST',
    messagesList: messagesList,
    sortOrder: sortOrder
  };
}

export function createTestMessage(mobileMessage, userId) {
  let newMessage = {
    message: mobileMessage.message,
    name: mobileMessage.name,
    type: mobileMessage.type,
    action_url: mobileMessage.action_url ? mobileMessage.action_url : '',
    customer_id: userId
  };

  // let customer_id = 'customer_id';
  // newMessage[customer_id] = userId;

  // delete newMessage.segments;
  // delete newMessage.send_now;
  // delete newMessage.send_at;

  return function(dispatch) {
    dispatch({ type: CREATE_TEST_MESSAGE_REQUEST });
    return api.post(`/message-blasts/tests`, newMessage).then(
      response => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Test Message successfully sent'
          })
        );
        dispatch({
          type: CREATE_TEST_MESSAGE_SUCCESS,
          mobileMessages: response.data
        });
        dispatch({
          type: RESET_TEST_MESSAGES
        });
      },
      error => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred trying to create Test Message'
          })
        );
        dispatch({
          type: CREATE_TEST_MESSAGE_FAILURE,
          error
        });
      }
    );
  };
}
