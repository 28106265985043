import {
  GET_DELIVERY_ZONE_OBJECTS_REQUEST,
  GET_DELIVERY_ZONE_OBJECTS_SUCCESS,
  DELETE_DELIVERY_ZONE_OBJECT_BY_ID_REQUEST,
  DELETE_DELIVERY_ZONE_OBJECT_BY_ID_SUCCESS,
  DELETE_DELIVERY_ZONE_OBJECT_BY_ID_FAILURE,
  UPDATE_DELIVERY_ZONE_OBJECT,
  UPDATE_EDITABLE_DELIVERY_ZONE,
  POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_REQUEST,
  POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_SUCCESS,
  POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_FAILURE,
  PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_REQUEST,
  PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_SUCCESS,
  PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_FAILURE,
  MAKE_DELIVERY_ZONE_NOT_EDITABLE_BY_ID,
  RESET_DELIVERYZONE_REDUCER_STATE,
} from './types';

import api from '../../../../../api';

import { createAlert } from '../../../../Alert/actions';

// let overlaysExample = [
//   {
//     id: 1,
//     type: 'circle',
//     zoneName: '2 km zone',
//     position: {
//       center: {lat: -33.96984876168996, lng: 149.358599609375},
//       radius: 111528.95060789163
//     },
//     zoneFee: 3,
//     isActive: true
//   },
//   {
//     id: 2,
//     type: 'rectangle',
//     zoneName: 'In town',
//     position: {
//       northEast: {lat: -33.828506263164094, lng: 149.688189453125},
//       northWest: {lat: -34.387934216418, lng: 149.688189453125},
//       southEast: {lat: -33.828506263164094, lng: 148.2325009765625},
//       southWest: {lat: -34.387934216418, lng: 148.2325009765625}
//     },
//     zoneFee: 4,
//     isActive: true
//   },
//   {
//     id: 3,
//     type: 'polygon',
//     zoneName: 'Out of town',
//     vertices: [
//       {lat: -33.54511957391775, lng: 149.56733984375},
//       {lat: -34.11095673161821, lng: 149.0125302734375},
//       {lat: -34.079114165688026, lng: 150.1111630859375}
//     ],
//     zoneFee: 5,
//     isActive: false
//   }
// ];

const formatDeliveryZones = (deliveryZones) => {
  let formattedDeliveryZones = deliveryZones.map((deliveryZone) => {
    let formattedDeliveryZone = {
      id: deliveryZone.id,
      businessId: deliveryZone.business_id,
      zoneName: deliveryZone.name,
      isActive: deliveryZone.is_active,
      zoneFee: deliveryZone.fee !== null ? String(deliveryZone.fee) : null,
      orderThreshold:
        deliveryZone.order_threshold !== null
          ? String(deliveryZone.order_threshold)
          : null,
      orderThresholdFee:
        deliveryZone.order_threshold_fee !== null
          ? String(deliveryZone.order_threshold_fee)
          : null,
    };

    if (deliveryZone.delivery_zone.type == 'circle') {
      formattedDeliveryZone.type = 'circle';
      formattedDeliveryZone.position = deliveryZone.delivery_zone.position;
    } else if (deliveryZone.delivery_zone.type == 'rectangle') {
      formattedDeliveryZone.type = 'rectangle';
      formattedDeliveryZone.position = deliveryZone.delivery_zone.position;
    } else if (deliveryZone.delivery_zone.type == 'polygon') {
      formattedDeliveryZone.type = 'polygon';
      formattedDeliveryZone.vertices = deliveryZone.delivery_zone.vertices;
    }

    return formattedDeliveryZone;
  });
  return formattedDeliveryZones;
};

export function getDeliveryZoneObjects(
  businessLocationId,
  editingDeliveryZoneId
) {
  return function (dispatch, getState) {
    let displayName = getState().businessLocationDetails
      .addressInformationInitialValues.address.display_name;
    let locationCoords = {
      lat: parseFloat(
        getState().businessLocationDetails.addressInformationInitialValues
          .address.latitude
      ),
      lng: parseFloat(
        getState().businessLocationDetails.addressInformationInitialValues
          .address.longitude
      ),
    };

    dispatch({ type: GET_DELIVERY_ZONE_OBJECTS_REQUEST });
    return Promise.all([
      api.get(`/businesses/${businessLocationId}/delivery-zones`),
      // api.get(`businesses/${businessLocationId}/detail`),
      // api.get(`businesses/${businessLocationId}/address`)
    ]).then((responses) => {
      let deliveryZones = responses[0].data.data;
      deliveryZones = formatDeliveryZones(deliveryZones);

      let colours = [
        '#5F27CD',
        '#FF9F43',
        '#10AC84',
        '#3B3B98',
        '#FF6B6B',
        '#1B9CFC',
        '#6D214F',
        '#F368E0',
      ];
      if (deliveryZones) {
        deliveryZones.forEach((deliveryZone) => {
          deliveryZone.colour =
            colours.length > 0 ? colours.shift() : '#000000';
          deliveryZone.colour = editingDeliveryZoneId
            ? deliveryZone.id != editingDeliveryZoneId
              ? 'rgba(0, 0, 0, 0.75)'
              : deliveryZone.colour
            : deliveryZone.colour;
          deliveryZone.isVisible = true;
          deliveryZone.isEditable = editingDeliveryZoneId
            ? deliveryZone.id == editingDeliveryZoneId
            : false;
          if (deliveryZone.isEditable) {
            dispatch({
              type: UPDATE_EDITABLE_DELIVERY_ZONE,
              payload: deliveryZone,
            });
          }
        });
      }
      dispatch({
        type: GET_DELIVERY_ZONE_OBJECTS_SUCCESS,
        deliveryZones,
        editingColour: colours.length > 0 ? colours.shift() : '#000000',
        displayName,
        locationCoords,
      });
    });
  };
}

export function deleteDeliveryZoneObjectById(
  businessLocationId,
  deliveryZoneId
) {
  return function (dispatch) {
    dispatch({ type: DELETE_DELIVERY_ZONE_OBJECT_BY_ID_REQUEST });
    return api
      .delete(`/delivery-zones/${deliveryZoneId}`)
      .then((response) => {
        dispatch({ type: DELETE_DELIVERY_ZONE_OBJECT_BY_ID_SUCCESS });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Delivery Zone Deleted Successfully',
          })
        );
        dispatch(getDeliveryZoneObjects(businessLocationId));
      })
      .catch((error) => {});
  };
}

export function updateDeliveryZoneObject(deliveryZone) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_DELIVERY_ZONE_OBJECT,
      payload: deliveryZone,
    });
  };
}

export function makeDeliveryZoneNotEditableById(deliveryZoneId) {
  return function (dispatch) {
    dispatch({
      type: MAKE_DELIVERY_ZONE_NOT_EDITABLE_BY_ID,
      payload: deliveryZoneId,
    });
  };
}

export function postDeliveryZoneObjectByLocationId(
  businessLocationId,
  deliveryZoneInfo
) {
  return function (dispatch) {
    dispatch({
      type: POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_REQUEST,
    });
    let formattedDeliveryZoneInfo = {
      business_id: parseInt(businessLocationId),
      name: deliveryZoneInfo.deliveryZoneFormValues.zoneName,
      fee: parseFloat(
        deliveryZoneInfo.deliveryZoneFormValues.zoneFee.replace('$', '')
      ),
      is_active: deliveryZoneInfo.deliveryZoneFormValues.isActive,
      delivery_zone: {
        type: deliveryZoneInfo.drawnOverlayZones[0].type,
      },
    };

    if (formattedDeliveryZoneInfo.delivery_zone.type == 'polygon') {
      formattedDeliveryZoneInfo.delivery_zone.vertices =
        deliveryZoneInfo.drawnOverlayZones[0].vertices;
    } else {
      formattedDeliveryZoneInfo.delivery_zone.position =
        deliveryZoneInfo.drawnOverlayZones[0].position;
    }

    formattedDeliveryZoneInfo.delivery_zone = JSON.stringify(
      formattedDeliveryZoneInfo.delivery_zone
    );

    if (
      deliveryZoneInfo.deliveryZoneFormValues.orderThreshold &&
      deliveryZoneInfo.deliveryZoneFormValues.orderThresholdFee
    ) {
      formattedDeliveryZoneInfo['order_threshold'] = deliveryZoneInfo
        .deliveryZoneFormValues.orderThreshold
        ? deliveryZoneInfo.deliveryZoneFormValues.orderThreshold.replace(
            '$',
            ''
          )
        : parseInt(0);

      formattedDeliveryZoneInfo['order_threshold_fee'] = deliveryZoneInfo
        .deliveryZoneFormValues.orderThresholdFee
        ? deliveryZoneInfo.deliveryZoneFormValues.orderThresholdFee.replace(
            '$',
            ''
          )
        : parseInt(0);
    } else {
      formattedDeliveryZoneInfo['order_threshold'] = null;
      formattedDeliveryZoneInfo['order_threshold_fee'] = null;
    }
    return api
      .post(`/delivery-zones`, formattedDeliveryZoneInfo)
      .then((response) => {
        dispatch({ type: POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_SUCCESS });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Delivery Zone Created Successfully',
          })
        );
        dispatch(getDeliveryZoneObjects(businessLocationId));
      })
      .catch((error) => {
        dispatch({ type: POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_FAILURE });
      });
  };
}

export function patchDeliveryZoneByBusinessLocationId(
  businessLocationId,
  deliveryZoneInfoObject
) {
  return function (dispatch) {
    let formattedDeliveryZone = {
      business_id: parseInt(businessLocationId),
      name: deliveryZoneInfoObject.deliveryZoneFormValues.zoneName,
      fee: deliveryZoneInfoObject.deliveryZoneFormValues.zoneFee.replace(
        '$',
        ''
      ),
      is_active: deliveryZoneInfoObject.deliveryZoneFormValues.isActive,
      delivery_zone: {
        type: deliveryZoneInfoObject.editDeliveryZone.type,
      },
    };

    if (
      deliveryZoneInfoObject.deliveryZoneFormValues.orderThresholdFee !== '' &&
      deliveryZoneInfoObject.deliveryZoneFormValues.orderThresholdFee !==
        null &&
      (deliveryZoneInfoObject.deliveryZoneFormValues.orderThreshold === '' ||
        deliveryZoneInfoObject.deliveryZoneFormValues.orderThreshold === null)
    ) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Must provide value for order threshold',
        })
      );

      return;
    }
    if (
      deliveryZoneInfoObject.deliveryZoneFormValues.orderThreshold !== '' &&
      deliveryZoneInfoObject.deliveryZoneFormValues.orderThreshold !== null
    ) {
      formattedDeliveryZone['order_threshold'] = deliveryZoneInfoObject
        .deliveryZoneFormValues.orderThreshold
        ? deliveryZoneInfoObject.deliveryZoneFormValues.orderThreshold.replace(
            '$',
            ''
          )
        : parseInt(0);
      if (formattedDeliveryZone['order_threshold'] < 1) {
        dispatch(
          createAlert({
            type: 'error',
            message: 'Order threshold must be blank or greater than 1',
          })
        );

        return;
      }
      if (
        deliveryZoneInfoObject.deliveryZoneFormValues.orderThresholdFee !==
          '' &&
        deliveryZoneInfoObject.deliveryZoneFormValues.orderThresholdFee !== null
      ) {
        formattedDeliveryZone['order_threshold_fee'] = deliveryZoneInfoObject
          .deliveryZoneFormValues.orderThresholdFee
          ? deliveryZoneInfoObject.deliveryZoneFormValues.orderThresholdFee.replace(
              '$',
              ''
            )
          : parseInt(0);
      } else {
        dispatch(
          createAlert({
            type: 'error',
            message: 'Must provide value for order threshold fee',
          })
        );
        return;
      }
    } else {
      formattedDeliveryZone['order_threshold'] = null;
      formattedDeliveryZone['order_threshold_fee'] = null;
    }

    if (formattedDeliveryZone.delivery_zone.type == 'polygon') {
      formattedDeliveryZone.delivery_zone.vertices =
        deliveryZoneInfoObject.editDeliveryZone.vertices;
    } else {
      formattedDeliveryZone.delivery_zone.position =
        deliveryZoneInfoObject.editDeliveryZone.position;
    }

    formattedDeliveryZone.delivery_zone = JSON.stringify(
      formattedDeliveryZone.delivery_zone
    );

    dispatch({
      type: PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_REQUEST,
    });

    return api
      .patch(
        `/delivery-zones/${deliveryZoneInfoObject.deliveryZoneFormValues.id}`,
        formattedDeliveryZone
      )
      .then((response) => {
        dispatch({
          type: PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_SUCCESS,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Delivery Zone Saved Successfully',
          })
        );
        dispatch(
          getDeliveryZoneObjects(
            businessLocationId,
            deliveryZoneInfoObject.deliveryZoneFormValues.id
          )
        );
      })
      .catch((error) => {
        dispatch({ type: PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_FAILURE });
      });
  };
}

export function alertError(message) {
  return function (dispatch) {
    dispatch(
      createAlert({
        type: 'error',
        message: message,
      })
    );
  };
}

export function resetDeliveryZoneReducerState() {
  return function (dispatch) {
    dispatch({ type: 'RESET_DELIVERYZONE_REDUCER_STATE' });
  };
}
