import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { handleCreateOptionOverrides } from './priceActions';
import styled from 'styled-components';
import OptionOverridesModal from './components/OptionOverridesModal';
import { finalStructureSummaryData } from './utils/helper';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { SUMMARY_SCREEN } from './priceTypes';

const OptionOverridesModalContainer = ({
  item,
  setIsOptionOverridesModal,
  selectedOption,
  formattedBusinesses,
  priceKey,
  singleItemOverrides,
  handleCreateOptionOverrides,
  page,
  setPage,
  items,
}) => {
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [summaryData, setSummaryData] = useState([]);

  const [isPageEdit, setIsPageEdit] = useState(false);

  const [editingVariant, setEditingVariant] = useState({
    assignedBusinesses: [],
    item: null,
    index: null,
  });

  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const [isConfirmOverridesModal, setIsConfirmOverridesModal] = useState(false);

  useEffect(() => {
    if (page === SUMMARY_SCREEN) {
      setSummaryData(
        finalStructureSummaryData(
          singleItemOverrides,
          item,
          formattedBusinesses,
          priceKey
        )
      );
    }
  }, [formattedBusinesses]);

  if (!formattedBusinesses) return <LoadingBlueLarge />;

  return (
    <OptionOverridesModal
      item={item}
      setIsOptionOverridesModal={setIsOptionOverridesModal}
      formattedBusinesses={formattedBusinesses}
      selectedOption={selectedOption}
      priceKey={priceKey}
      selectedLocations={selectedLocations}
      setSelectedLocations={setSelectedLocations}
      singleItemOverrides={singleItemOverrides}
      handleCreateOptionOverrides={handleCreateOptionOverrides}
      page={page}
      setPage={setPage}
      summaryData={summaryData}
      setSummaryData={setSummaryData}
      isPageEdit={isPageEdit}
      setIsPageEdit={setIsPageEdit}
      editingVariant={editingVariant}
      setEditingVariant={setEditingVariant}
      items={items}
      isConfirmModal={isConfirmModal}
      setIsConfirmModal={setIsConfirmModal}
      isConfirmOverridesModal={isConfirmOverridesModal}
      setIsConfirmOverridesModal={setIsConfirmOverridesModal}
    />
  );
};

export default connect(null, {
  handleCreateOptionOverrides,
})(OptionOverridesModalContainer);
