import styled from 'styled-components';

export const GridWrapper = styled.div`
  display: grid;
  grid-gap: 15px;
`;

export const Card = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background: ${props => props.theme.white};
  padding: 30px;
  display: grid;
  grid-gap: 60px;
`;

export const CardRow = styled(Card)`
  width: 500px;
  padding: 15px;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const FormBox = styled.div`
  width: 500px;
  display: grid;
  grid-template: repeat(3, auto) / repeat(6, 1fr);
  align-items: flex-start;
  grid-gap: 15px 45px;
  padding: 15px;
  border: ${props => props.theme.borderBlue};
`;

export const SortableCardRow = styled(CardRow)`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'move')};
`;
