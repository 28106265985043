import React, {Component} from 'react';
import styled from 'styled-components';
import toPriceFormat from '../../../../utils/toPriceFormat';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipIcon } from '../../../../components/Icon';

class SummaryBar extends Component {
  state = {};

  render() {
    const {data} = this.props;

    return (
      <Summary>
        
        <UsersData>
          Total Transactions {' '}
          <Tooltip title="The total number of transactions" placement="top" arrow>
            <span> <TooltipIcon/> </span>
          </Tooltip>
          <UsersNumber>
            {data.transaction_counts.total.toLocaleString()}
          </UsersNumber>
          {data.transaction_counts.percent_change !== 0 &&
            (data.transaction_counts.percent_change > 0 ? (
              <PercentageUp>
                <i className="fa fa-arrow-up" />{' '}
                {data.transaction_counts.percent_change.toLocaleString()}%
              </PercentageUp>
            ) : (
              <PercentageDown>
                <i className="fa fa-arrow-down" />{' '}
                {Math.abs(
                  data.transaction_counts.percent_change
                ).toLocaleString()}
                %
              </PercentageDown>
            ))}
          {data.transaction_counts.percent_change !== 0 && (
            <CompareText>vs previous period</CompareText>
          )}
        </UsersData>
        <UsersData>
          First Time Transactions {' '}
          <Tooltip title="The total number of transactions for all users who made their first purchase" placement="top" arrow>
            <span> <TooltipIcon/> </span>
          </Tooltip>
          <UsersNumber>
            {data.first_time_transactions.total.toLocaleString()}
          </UsersNumber>
          {data.first_time_transactions.percent_change !== 0 &&
            (data.first_time_transactions.percent_change > 0 ? (
              <PercentageUp>
                <i className="fa fa-arrow-up" />{' '}
                {data.first_time_transactions.percent_change.toLocaleString()}%
              </PercentageUp>
            ) : (
              <PercentageDown>
                <i className="fa fa-arrow-down" />{' '}
                {Math.abs(
                  data.first_time_transactions.percent_change
                ).toLocaleString()}
                %
              </PercentageDown>
            ))}
          {data.first_time_transactions.percent_change !== 0 && (
            <CompareText>vs previous period</CompareText>
          )}
        </UsersData>
        <UsersData>
          Amount of Sales {' '}
          <Tooltip title="The total revenue generated" placement="top" arrow>
            <span> <TooltipIcon/> </span>
          </Tooltip>
          <UsersNumber>{toPriceFormat(data.sales_counts.total)}</UsersNumber>
          {data.sales_counts.percent_change !== 0 &&
            (data.sales_counts.percent_change > 0 ? (
              <PercentageUp>
                <i className="fa fa-arrow-up" />{' '}
                {data.sales_counts.percent_change.toLocaleString()}%
              </PercentageUp>
            ) : (
              <PercentageDown>
                <i className="fa fa-arrow-down" />{' '}
                {Math.abs(data.sales_counts.percent_change).toLocaleString()}%
              </PercentageDown>
            ))}
          {data.sales_counts.percent_change !== 0 && (
            <CompareText>vs previous period</CompareText>
          )}
        </UsersData>
        <UsersData>
          Average Transaction {' '}
          <Tooltip title="The average revenue generated given all transactions" placement="top" arrow>
            <span> <TooltipIcon /> </span>
          </Tooltip>
          <UsersNumber>
            {toPriceFormat(data.avg_transaction_amounts.total)}
          </UsersNumber>
          {data.avg_transaction_amounts.percent_change !== 0 &&
            (data.avg_transaction_amounts.percent_change > 0 ? (
              <PercentageUp>
                <i className="fa fa-arrow-up" />{' '}
                {data.avg_transaction_amounts.percent_change.toLocaleString()}%
              </PercentageUp>
            ) : (
              <PercentageDown>
                <i className="fa fa-arrow-down" />{' '}
                {Math.abs(
                  data.avg_transaction_amounts.percent_change
                ).toLocaleString()}
                %
              </PercentageDown>
            ))}
          {data.avg_transaction_amounts.percent_change !== 0 && (
            <CompareText>vs previous period</CompareText>
          )}
        </UsersData>
      </Summary>
    );
  }
}

const Summary = styled.div`
  display: grid;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
  margin-top: 30px;
  background: ${props => props.theme.white};
  border-radius: 12px;
  grid-template-columns: repeat(4, 1fr);
`;

const UsersData = styled.div`
  padding: 16px 0px 8px 0px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-right: 1px solid #c9cbcf;
  width: 100%;
`;
const UsersNumber = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #296bde;
`;
const PercentageUp = styled.div`
  font-size: 16px;
  color: #1abc9c;
  font-weight: normal;
`;
const PercentageDown = styled.div`
  font-size: 16px;
  color: #ff7070;
  font-weight: normal;
`;
const CompareText = styled.div`
  font-size: 10px;
  color: #979eaa;
  font-weight: bold;
`;

export default SummaryBar;
