import {
  GET_SUMMARY,
  GET_TRANSACTIONS,
  GET_SALES,
  GET_TRANSACTIONS_PER_HOUR,
  GET_ORDER_TYPES,
  LOADING
} from './types';

const initialFormValues = {
  time_period: {
    interval_type: 'day',
    interval_value: 30
  }
};

export default function(
  state = {
    initialFormValues
  },
  action
) {
  switch (action.type) {
    case GET_SUMMARY:
      return {
        ...state,
        summaryData: action.payload.data,
        loading: true
      };
    case GET_TRANSACTIONS:
      return {...state, transactionsData: action.payload.data};
    case GET_SALES:
      return {...state, salesData: action.payload.data};
    case GET_TRANSACTIONS_PER_HOUR:
      return {...state, transactionsPerHourData: action.payload.data};
    case GET_ORDER_TYPES:
      return {...state, orderTypesData: action.payload.data};
    case LOADING:
      return {...state, loading: false};
    default:
      return {...state};
  }
  return state;
}
