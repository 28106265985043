import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actions from './appFeedActions';
import { CardRow } from './components/AppFeedElements';
import Icon, { IconWrapper, EditIcon, DeleteIcon } from '../../components/Icon';
import Tooltip from '@material-ui/core/Tooltip';

const TitleWrapper = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FeedRowImg = styled.img`
  height: 32px;
  width: 32px;
  object-fit: cover;
`;

class FeedRow extends Component {
  //inner div is needed to assign a unqiue key /className, since className for Icon is fafa
  renderLockIcon = () => {
    return (
      <Tooltip title="Locked">
        <div className={this.props.feedRow.id}>
          <Icon className="fal fa-lock" style={{ cursor: 'inherit' }} />{' '}
        </div>
      </Tooltip>
    );
  };

  renderTrashIcon = () => {
    return (
      <Tooltip title="Delete">
        <div className={this.props.feedRow.id}>
          <i
            style={{ cursor: 'pointer' }}
            className="fal fa-trash-alt"
            onClick={() =>
              this.props.deleteFeedRow(this.props.feedRow) &&
              this.props.valueChanged(true)
            }
          />
        </div>
      </Tooltip>
    );
  };

  renderTrashOrLockIcon = () => {
    if (
      this.props.feedRow.is_required ||
      this.props.feedRow.feed_type !== 'custom'
    ) {
      // return this.renderLockIcon();
    } else {
      return this.renderTrashIcon();
    }
  };

  cancelEditFeedRow = () => {
    this.props.updateFeedRow(this.props.feedRowBeingEdited);
    this.props.cancelEditFeedRowForm();
  };

  calculateTotalHeight = () => {
    this.props.setId(this.props.feedRow.id);
  };

  render() {
    const {
      feedRowIsEditing,
      feedRowFormIsVisible,
      feedRow,
      openEditFeedRowForm
    } = this.props;

    function isEmpty(str) {
      return !/\S/.test(str);
    }

    return (
      <div>
        <CardRow
          onClick={() => this.calculateTotalHeight()}
          sortDisabled={feedRowIsEditing || feedRow.is_pinned}
          is_visible={feedRow.is_visible}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '64px 1fr auto',
              alignItems: 'center',
              height: '100%'
            }}
          >
            {feedRow.items &&
            feedRow.items.length &&
            feedRow.items[0].background_image_url ? (
              <FeedRowImg src={feedRow.items[0].background_image_url} />
            ) : (
              <div />
            )}
            <TitleWrapper>
              {feedRow.internal_label ||
                (feedRow.items[0]
                  ? feedRow.items[0].title
                    ? isEmpty(feedRow.items[0].title)
                      ? feedRow.title
                      : feedRow.items[0].title
                    : feedRow.title
                  : feedRow.title)}
            </TitleWrapper>

            {
              <IconWrapper>
                {this.props.feedRow.is_pinned && (
                  <Tooltip title="Pin">
                    <Icon className="fal fa-thumbtack" cursor="inherit" />
                  </Tooltip>
                )}
                {!this.props.feedRow.is_pinned && this.renderTrashOrLockIcon()}

                {!feedRowIsEditing && !feedRowFormIsVisible && (
                  <Tooltip title="Edit">
                    <div>
                      <i
                        style={{ cursor: 'pointer' }}
                        className="fal fa-pencil"
                        onClick={() => {
                          openEditFeedRowForm(feedRow);
                        }}
                      />
                    </div>
                  </Tooltip>
                )}
              </IconWrapper>
            }
          </div>
        </CardRow>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    feedRowIsEditing: state.appFeed.feedRowIsEditing,
    feedRowBeingEdited: state.appFeed.feedRowBeingEdited,
    feedRowFormIsVisible: state.appFeed.feedRowFormIsVisible
  };
}

export default connect(mapStateToProps, actions)(FeedRow);
