export const userSummaryData = {
  data: {
    data: {
      active_user_counts: {
        total: 4195,
        percent_change: 5
      },
      new_user_sign_up_counts: {
        total: 946,
        percent_change: -3
      },
      first_time_user_counts: {
        total: 671,
        percent_change: 12
      },
      conversion: {
        total: 73,
        percent_change: 8
      }
    }
  }
};

export const activeUsersData = {
  data: {
    data: {
      dates: [
        // "2018-05-16",
        // "2018-05-17",
        // "2018-05-18",
        // "2018-05-19",
        // "2018-05-20",
        // "2018-05-21",
        // "2018-05-22"
        '2018-05-15',
        '2018-05-16',
        '2018-05-17',
        '2018-05-18',
        '2018-05-19',
        '2018-05-20',
        '2018-05-21',
        '2018-05-22',
        '2018-05-23',
        '2018-05-24',
        '2018-05-25',
        '2018-05-26',
        '2018-05-27',
        '2018-05-28'
      ],
      period_now: [410, 519, 425, 522, 319, 416, 317],
      period_prev: [222, 453, 311, 412, 216, 311, 227],
      total: 2853
    }
  }
};

export const userSignUpsData = {
  data: {
    data: {
      dates: [
        '2018-05-15',
        '2018-05-16',
        '2018-05-17',
        '2018-05-18',
        '2018-05-19',
        '2018-05-20',
        '2018-05-21',
        '2018-05-22',
        '2018-05-23',
        '2018-05-24',
        '2018-05-25',
        '2018-05-26',
        '2018-05-27',
        '2018-05-28'
      ],
      period_now: [51, 40, 31, 60, 70, 50, 30],
      period_prev: [40, 30, 20, 40, 50, 30, 20],
      total: 410
    }
  }
};

export const referralStatsData = {
  data: {
    data: {
      total_referrals: 457,
      total_converted: 186
    }
  }
};

export const userAgeData = {
  data: {
    data: {
      ages: ['<20', '20-27', '28-37', '38-47', '48-57', '58-67', '68+'],
      counts: [88, 253, 479, 328, 214, 87, 77]
    }
  }
};

export const cumulativeUsersData = {
  data: {
    data: {
      dates: [
        // '2017-06',
        // '2017-07',
        // '2017-08',
        // '2017-09',
        // '2017-10',
        // '2017-11',
        '2017-12',
        '2018-01',
        '2018-02',
        '2018-03',
        '2018-04',
        '2018-05'
      ],
      counts: [
        //0,437,884,1047,1458,1600,
        1854,
        2022,
        2321,
        2489,
        2630,
        2890
      ],
      total: 44
    }
  }
};

// USER DASHBOARD 30 Days =========================================================================================================

export const userSummaryData30 = {
  data: {
    data: {
      active_user_counts: {
        total: 16218,
        percent_change: 7
      },
      new_user_sign_up_counts: {
        total: 486,
        percent_change: 11
      },
      first_time_user_counts: {
        total: 416,
        percent_change: 4
      },
      conversion: {
        total: 67,
        percent_change: 7
      }
    }
  }
};

export const activeUsersData30 = {
  data: {
    data: {
      dates: [
        '2018-03-30',
        '2018-03-31',
        '2018-04-01',
        '2018-04-02',
        '2018-04-03',
        '2018-04-04',
        '2018-04-05',
        '2018-04-06',
        '2018-04-07',
        '2018-04-08',
        '2018-04-09',
        '2018-04-10',
        '2018-04-11',
        '2018-04-12',
        '2018-04-13',
        '2018-04-14',
        '2018-04-15',
        '2018-04-16',
        '2018-04-17',
        '2018-04-18',
        '2018-04-19',
        '2018-04-20',
        '2018-04-21',
        '2018-04-22',
        '2018-04-23',
        '2018-04-24',
        '2018-04-25',
        '2018-04-26',
        '2018-04-27',
        '2018-04-28',
        '2018-04-29',
        '2018-04-30',
        '2018-05-01',
        '2018-05-02',
        '2018-05-03',
        '2018-05-04',
        '2018-05-05',
        '2018-05-06',
        '2018-05-07',
        '2018-05-08',
        '2018-05-09',
        '2018-05-10',
        '2018-05-11',
        '2018-05-12',
        '2018-05-13',
        '2018-05-14',
        '2018-05-15',
        '2018-05-16',
        '2018-05-17',
        '2018-05-18',
        '2018-05-19',
        '2018-05-20',
        '2018-05-21',
        '2018-05-22',
        '2018-05-23',
        '2018-05-24',
        '2018-05-25',
        '2018-05-26',
        '2018-05-27',
        '2018-05-28'
      ],
      period_now: [
        460,
        519,
        425,
        522,
        319,
        416,
        317,
        280,
        223,
        344,
        418,
        378,
        340,
        419,
        333,
        328,
        420,
        547,
        510,
        483,
        467,
        389,
        348,
        290,
        278,
        310,
        417,
        324,
        327,
        432,
        224
      ],
      period_prev: [
        322,
        453,
        311,
        412,
        216,
        311,
        227,
        240,
        313,
        414,
        448,
        318,
        280,
        379,
        293,
        318,
        400,
        511,
        413,
        392,
        414,
        342,
        319,
        234,
        190,
        216,
        221,
        219,
        218,
        220,
        189
      ],
      total: 16218
    }
  }
};

export const userSignUpsData30 = {
  data: {
    data: {
      dates: [
        '2018-03-30',
        '2018-03-31',
        '2018-04-01',
        '2018-04-02',
        '2018-04-03',
        '2018-04-04',
        '2018-04-05',
        '2018-04-06',
        '2018-04-07',
        '2018-04-08',
        '2018-04-09',
        '2018-04-10',
        '2018-04-11',
        '2018-04-12',
        '2018-04-13',
        '2018-04-14',
        '2018-04-15',
        '2018-04-16',
        '2018-04-17',
        '2018-04-18',
        '2018-04-19',
        '2018-04-20',
        '2018-04-21',
        '2018-04-22',
        '2018-04-23',
        '2018-04-24',
        '2018-04-25',
        '2018-04-26',
        '2018-04-27',
        '2018-04-28',
        '2018-04-29',
        '2018-04-30',
        '2018-05-01',
        '2018-05-02',
        '2018-05-03',
        '2018-05-04',
        '2018-05-05',
        '2018-05-06',
        '2018-05-07',
        '2018-05-08',
        '2018-05-09',
        '2018-05-10',
        '2018-05-11',
        '2018-05-12',
        '2018-05-13',
        '2018-05-14',
        '2018-05-15',
        '2018-05-16',
        '2018-05-17',
        '2018-05-18',
        '2018-05-19',
        '2018-05-20',
        '2018-05-21',
        '2018-05-22',
        '2018-05-23',
        '2018-05-24',
        '2018-05-25',
        '2018-05-26',
        '2018-05-27',
        '2018-05-28'
      ],
      period_now: [
        21,
        63,
        110,
        82,
        90,
        76,
        61,
        51,
        40,
        31,
        60,
        70,
        50,
        30,
        23,
        55,
        69,
        87,
        95,
        89,
        46,
        72,
        104,
        44,
        34,
        51,
        62,
        50,
        66,
        85,
        88
      ],
      period_prev: [
        11,
        43,
        80,
        62,
        70,
        86,
        71,
        67,
        33,
        21,
        50,
        60,
        54,
        27,
        17,
        45,
        59,
        67,
        85,
        79,
        66,
        52,
        84,
        41,
        30,
        45,
        55,
        48,
        61,
        79,
        88
      ],
      total: 1410
    }
  }
};

export const referralStatsData30 = {
  data: {
    data: {
      total_referrals: 1457,
      total_converted: 1186
    }
  }
};

export const userAgeData30 = {
  data: {
    data: {
      ages: ['<20', '20-27', '28-37', '38-47', '48-57', '58-67', '68+'],
      counts: [288, 853, 679, 528, 414, 187, 177]
    }
  }
};

// USER DASHBOARD 90 Days =========================================================================================================

export const userSummaryData90 = {
  data: {
    data: {
      active_user_counts: {
        total: 48456,
        percent_change: 5
      },
      new_user_sign_up_counts: {
        total: 1257,
        percent_change: 13
      },
      first_time_user_counts: {
        total: 1222,
        percent_change: 12
      },
      conversion: {
        total: 54,
        percent_change: 7
      }
    }
  }
};

export const activeUsersData90 = {
  data: {
    data: {
      dates: [
        '2018-03-02',
        '2018-03-09',
        '2018-03-16',
        '2018-03-23',
        '2018-03-30',
        '2018-04-06',
        '2018-04-13',
        '2018-04-20',
        '2018-04-27',
        '2018-05-04',
        '2018-05-11',
        '2018-05-18',
        '2018-05-25'
      ],
      period_now: [
        1266,
        1534,
        1235,
        1522,
        1229,
        1226,
        984,
        884,
        673,
        1032,
        1242,
        1088,
        1088
      ],
      period_prev: [
        1266,
        1534,
        1235,
        1522,
        1229,
        1226,
        984,
        884,
        673,
        1032,
        1242,
        1088,
        1088
      ],
      total: 48456
    }
  }
};

export const userSignUpsData90 = {
  data: {
    data: {
      dates: [
        '2018-03-02',
        '2018-03-09',
        '2018-03-16',
        '2018-03-23',
        '2018-03-30',
        '2018-04-06',
        '2018-04-13',
        '2018-04-20',
        '2018-04-27',
        '2018-05-04',
        '2018-05-11',
        '2018-05-18',
        '2018-05-25'
      ],
      period_now: [
        61,
        183,
        310,
        242,
        270,
        216,
        181,
        151,
        120,
        91,
        180,
        210,
        150
      ],
      period_prev: [
        31,
        123,
        240,
        182,
        210,
        246,
        211,
        187,
        93,
        61,
        150,
        180,
        154
      ],
      total: 1257
    }
  }
};

export const referralStatsData90 = {
  data: {
    data: {
      total_referrals: 4247,
      total_converted: 3475
    }
  }
};

export const userAgeData90 = {
  data: {
    data: {
      ages: ['<20', '20-27', '28-37', '38-47', '48-57', '58-67', '68+'],
      counts: [748, 2488, 1987, 1550, 1280, 451, 412]
    }
  }
};

// USER DASHBOARD 1 Year =========================================================================================================

export const userSummaryData1 = {
  data: {
    data: {
      active_user_counts: {
        total: 148456,
        percent_change: 5
      },
      new_user_sign_up_counts: {
        total: 5257,
        percent_change: 13
      },
      first_time_user_counts: {
        total: 5222,
        percent_change: 12
      },
      conversion: {
        total: 204,
        percent_change: 8
      }
    }
  }
};

export const activeUsersData1 = {
  data: {
    data: {
      dates: [
        '2017-06',
        '2017-07',
        '2017-08',
        '2017-09',
        '2017-10',
        '2017-11',
        '2017-12',
        '2018-01',
        '2018-02',
        '2018-03',
        '2018-04',
        '2018-05'
      ],
      period_now: [
        1266,
        1534,
        1235,
        1522,
        1229,
        1226,
        984,
        884,
        673,
        1032,
        1242,
        1088
      ],
      period_prev: [
        1066,
        1234,
        1035,
        1422,
        1029,
        926,
        884,
        784,
        611,
        932,
        1042,
        988
      ],
      total: 148456
    }
  }
};

export const userSignUpsData1 = {
  data: {
    data: {
      dates: [
        '2017-06',
        '2017-07',
        '2017-08',
        '2017-09',
        '2017-10',
        '2017-11',
        '2017-12',
        '2018-01',
        '2018-02',
        '2018-03',
        '2018-04',
        '2018-05'
      ],
      period_now: [61, 183, 310, 242, 270, 216, 181, 151, 120, 91, 180, 210],
      period_prev: [31, 123, 240, 182, 210, 246, 211, 187, 93, 61, 150, 180],
      total: 5257
    }
  }
};

export const referralStatsData1 = {
  data: {
    data: {
      total_referrals: 12247,
      total_converted: 9475
    }
  }
};

export const userAgeData1 = {
  data: {
    data: {
      ages: ['<20', '20-27', '28-37', '38-47', '48-57', '58-67', '68+'],
      counts: [2848, 8488, 8087, 6050, 4880, 1651, 1612]
    }
  }
};
