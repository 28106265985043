import React from 'react';

const ProductTagsSummary = ({productTagsInitialValues, allProductTags}) => {
  let displayTags = productTagsInitialValues.map(tagId => {
    let foundTag = allProductTags.find(
      productTag => productTag.tag_id === tagId
    );
    return foundTag ? foundTag.name : 'Tag Not Found';
  });

  return <div>{displayTags.join(', ')}</div>;
};

export default ProductTagsSummary;
