import React from 'react';
import styled from 'styled-components';

const LocationsWrapper = styled.div`
  position: relative;
  overflow-y: scroll;
  height: calc(100% - 18px);
  border-left: 1px solid lightgray;
  padding-left: 15px;
  margin-top: 15px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.checkBlue};
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
`;

const AbsoluteWrapper = styled.div`
  position: absolute;
`;

const Label = styled.div`
  padding-left: 15px;
  margin-bottom: -10px;
  font-weight: bold;
  color: ${props => props.theme.darkBlue};
  text-transform: uppercase;
`;

const LocationName = styled.p`
  margin: 8px;
`;

const getTargetedLocations = (selectedLocations, drawnOverlayZone) => {
  if (drawnOverlayZone.type == 'circle') {
    return selectedLocations.filter(location => {
      return (
        google.maps.geometry.spherical.computeDistanceBetween(
          new google.maps.LatLng(location.lat, location.lng),
          drawnOverlayZone.mapObj.getCenter()
        ) <= drawnOverlayZone.mapObj.getRadius()
      );
    });
  } else if (drawnOverlayZone.type == 'rectangle') {
    return selectedLocations.filter(location => {
      return drawnOverlayZone.mapObj
        .getBounds()
        .contains(new google.maps.LatLng(location.lat, location.lng));
    });
  } else if (drawnOverlayZone.type == 'polygon') {
    return selectedLocations.filter(location => {
      return google.maps.geometry.poly.containsLocation(
        new google.maps.LatLng(location.lat, location.lng),
        drawnOverlayZone.mapObj
      );
    });
  }
};

const LocationsTargeted = ({ selectedLocations, drawnOverlayZones }) => {
  const targetedLocations =
    selectedLocations && drawnOverlayZones && drawnOverlayZones.length > 0
      ? getTargetedLocations(selectedLocations, drawnOverlayZones[0])
      : selectedLocations
      ? selectedLocations
      : [];

  return (
    <div>
      <Label>Locations Targeted ({targetedLocations.length})</Label>
      <LocationsWrapper>
        <AbsoluteWrapper>
          {targetedLocations.map((location, index) => (
            <LocationName key={`${location.name}${index}`}>
              {location.display_name}
            </LocationName>
          ))}
        </AbsoluteWrapper>
      </LocationsWrapper>
    </div>
  );
};

export default LocationsTargeted;
