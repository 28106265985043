export const GET_TAGS_REQUEST = 'GET_TAGS_REQUEST';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'GET_TAGS_FAILURE';

export const CREATE_TAG_REQUEST = 'CREATE_TAG_REQUEST';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_FAILURE = 'CREATE_TAG_FAILURE';

export const EDIT_TAG_REQUEST = 'EDIT_TAG_REQUEST';
export const EDIT_TAG_SUCCESS = 'EDIT_TAG_SUCCESS';
export const EDIT_TAG_FAILURE = 'EDIT_TAG_FAILURE';

export const SHOW_TAG_FORM = 'SHOW_TAG_FORM';
export const HIDE_TAG_FORM = 'HIDE_TAG_FORM';

export const SHOW_DELETE_TAG_MODAL = 'SHOW_DELETE_TAG_MODAL';
export const HIDE_DELETE_TAG_MODAL = 'HIDE_DELETE_TAG_MODAL';

export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE';

export const RESET_TAGS_REDUCER_STATE = 'RESET_TAGS_REDUCER_STATE';
