import React, {useState} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../../productsActions';
import styled from 'styled-components';
import SelectionRow from './SelectionRow';
import Label from './Label';
import Collapse from '@material-ui/core/Collapse';
import CircleCheck from './CircleCheck';
import toPriceFormat from '../../../../../../../utils/toPriceFormat';

const Wrapper = styled.div`
  @media (min-width: 768px) {
    grid-column: span 1;
  }
`;

const VariantDropdown = styled.div`
  display: grid;
  grid-gap: 4px;
  padding: 4px;
  background: ${props => '#ad0d1e'};
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.1),
    inset 0 -3px 5px 0 rgba(0, 0, 0, 0.1);
`;

const VariantRow = styled.div`
  height: 68px;
  display: grid;
  grid-template: 1fr 1fr / 1fr auto;
  align-items: center;
  padding: 10px;
  background: white;
  cursor: pointer;
`;

const VariantName = styled.div`
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: ${props => '#ad0d1e'};
  grid-area: 1 / 1 / 3 / 2;
`;

const Price = styled.div`
  font-size: 14px;
  grid-area: 2 / 2 / 3 / 3;
`;

const SizeDrawer = ({
  prices,
  selectedVariantId,
  changeVariant,
  flatProduct,
  selectedPickUpLocation
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleDrawer = () => setIsExpanded(!isExpanded);

  const selectedVariant = prices.find(
    ({variant_id}) => variant_id === selectedVariantId
  );

  const selectVariant = variant => {
    changeVariant({
      productId: flatProduct.product_id,
      variantId: variant.variant_id,
      businessId: 251
    });
    setIsExpanded(false);
  };

  return (
    <Wrapper>
      <Label>size</Label>
      <SelectionRow onClick={toggleDrawer} opened={isExpanded}>
        {selectedVariant.name}
        <i className={`fal fa-chevron-${isExpanded ? 'up' : 'down'}`} />
      </SelectionRow>
      <Collapse in={isExpanded}>
        <VariantDropdown>
          {flatProduct.prices.map(variant => {
            return (
              <VariantRow
                onClick={() => selectVariant(variant)}
                key={variant.variant_id}
              >
                <VariantName>{variant.name}</VariantName>
                {selectedVariant.variant_id === variant.variant_id && (
                  <div style={{justifySelf: 'flex-end'}}>
                    <CircleCheck />
                  </div>
                )}
                <Price>{toPriceFormat(variant.price)}</Price>
              </VariantRow>
            );
          })}
        </VariantDropdown>
      </Collapse>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  flatProduct: state.productsReducer.productCustomizerReducer.flatProduct,
  prices: state.productsReducer.productCustomizerReducer.flatProduct.prices,
  selectedVariantId:
    state.productsReducer.productCustomizerReducer.selectedVariantId
});

export default connect(
  mapStateToProps,
  actions
)(SizeDrawer);
