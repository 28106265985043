import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import {
  RenderTextField,
  isExtraChargePriceValidation,
} from '../../../../../../components/FormElements/FormikElements';
import { Modal } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';

const ButtonsWrapper = styled.div`
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-gap: 120px;
  padding: 20px;
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.12);
  text-align: right;
`;

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px 20px;
`;

const ModalWrapper = styled.div`
  padding: 24px;
  color: #191818;
`;

const LineDivider = styled.hr`
  border: solid 1px #ebebeb;
  margin-bottom: 23px;
  margin-top: 15px;
`;

const Text = styled.div`
  margin-top: 23px;
  margin-bottom: 16px;
`;

const PriceField = styled.div`
  width: 200px;
  margin-bottom: 30px;
`;

const ChargeExtraModal = ({
  toggleChargeExtraModal,
  productVariant,
  comboItemId,
  editingComboId,
  setFieldValue,
  comboItemIndex,
  arrayHelpers,
  productVariantIndex,
  handleReset,
}) => {
  const hasError =
    arrayHelpers.form.touched.comboItems &&
    arrayHelpers.form.touched.comboItems[comboItemIndex] &&
    arrayHelpers.form.touched.comboItems[comboItemIndex].productVariants &&
    arrayHelpers.form.touched.comboItems[comboItemIndex].productVariants[
      productVariantIndex
    ] &&
    arrayHelpers.form.touched.comboItems[comboItemIndex].productVariants[
      productVariantIndex
    ].productExtraPrice &&
    isExtraChargePriceValidation(
      arrayHelpers.form.values.comboItems[comboItemIndex].productVariants[
        productVariantIndex
      ].productExtraPrice
    );
  return (
    <Modal>
      <ModalWrapper>
        <ModalTitle>
          <div>
            {productVariant.productName} - {productVariant.variantName} Upgrade
            Cost
          </div>
          <div />
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => toggleChargeExtraModal()}
          >
            x
          </div>
        </ModalTitle>
        <LineDivider />
        <Text>Specify how much you want to charge for this product below</Text>
        <PriceField>
          <Field
            name={`comboItems[${comboItemIndex}].productVariants[${productVariantIndex}].productExtraPrice`}
            component={RenderTextField}
            label="Extra Price Charge"
            error={hasError}
          />
        </PriceField>
      </ModalWrapper>

      <ButtonsWrapper>
        <Button
          disabled={hasError}
          onClick={() => {
            toggleChargeExtraModal();
          }}
        >
          Close
        </Button>
      </ButtonsWrapper>
    </Modal>
  );
};

export default ChargeExtraModal;
