import React, {Component} from 'react';
import api from '../../api';
import renderQueryString from '../../utils/renderQueryString';

function exportCSVWrapper(WrappedComponent) {
  return class extends Component {
    state = {
      requestingCSV: false,
      isDownloading: []
    };

    exportCSV = ({requestPath, fileName, requestParams, id}) => {
      const downloadAsCSV = (function() {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        return function(data, fileName) {
          const blob = new Blob([data], {type: 'octet/stream'}),
            url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        };
      })();

      this.setState({
        requestingCSV: true,
        isDownloading: [...this.state.isDownloading, id]
      });
      let queryStringRequestParams;
      if (requestParams) {
        let queryString = renderQueryString(requestParams);
        queryStringRequestParams = `${queryString}&export=true`;
      } else {
        queryStringRequestParams = '?export=true';
      }

      return api
        .get(`/${requestPath}${queryStringRequestParams}`)
        .then(response => {
          downloadAsCSV(response.data, `${fileName}.csv`);
          this.setState({
            requestingCSV: false,
            isDownloading: this.state.isDownloading.filter(ids => ids !== id)
          });
        })
        .catch(error => {
          // console.log(error);
        });
    };

    render() {
      return (
        <WrappedComponent
          exportCSV={this.exportCSV}
          requestingCSV={this.state.requestingCSV}
          isDownloading={this.state.isDownloading}
          {...this.props}
        />
      );
    }
  };
}

export default exportCSVWrapper;
