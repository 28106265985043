import {
  GET_CUSTOMER_REVIEWS_REQUEST,
  GET_CUSTOMER_REVIEWS_SUCCESS,
  GET_CUSTOMER_REVIEWS_FAILURE
} from './feedbackTypes';
import api from '../../../api';
import renderQueryString from '../../../utils/renderQueryString';

export function getreviews(params) {
  return function(dispatch) {
    dispatch({
      type: GET_CUSTOMER_REVIEWS_REQUEST
    });
    api
      .get(`/reviews${renderQueryString(params)}`)
      .then(response => {
        dispatch({
          type: GET_CUSTOMER_REVIEWS_SUCCESS,
          reviews: response.data.data,
          meta: response.data.meta
        });
      })
      .catch(error => {
        dispatch({
          type: GET_CUSTOMER_REVIEWS_FAILURE
        });
      });
  };
}
