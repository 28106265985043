import React from 'react';
import styled from 'styled-components';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackgroundCircle = styled.circle`
  fill: none;
  stroke: #babdc4;
`;

const PercentageText = styled.tspan`
  font-size: 20px;
  font-weight: bold;
  fill: #949aaa;
`;

const OfUsersText = PercentageText.extend`
  font-size: 8px;
`;

const ProgressCircle = ({percentage}) => {
  // Size of the enclosing square
  const sqSize = 100;

  const strokeWidth = 10;

  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 104 104`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  const InnerCircle = styled.circle`
    fill: none;
    stroke-dasharray: ${dashArray};
    stroke-dashoffset: ${dashOffset};
    }
  `;

  return (
    <FlexWrapper>
      <svg width={'100%'} height={'100%'} viewBox={viewBox}>
        <defs>
          <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#4869D3" />
            <stop offset="100%" stopColor="#0E85D6" />
          </linearGradient>
          <filter id="shadow">
            <feDropShadow
              dx="-1"
              dy="1"
              stdDeviation="2"
              floodColor="rgba(0, 0, 0, 0.5)"
            />
          </filter>
        </defs>
        <BackgroundCircle
          className="circle-background"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <InnerCircle
          className="circle-progress"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          stroke="url(#linear)"
          filter="url(#shadow)"
        />
        <text
          className="circle-text"
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle"
        >
          <PercentageText x="50%" dy="0">{`${percentage}%`}</PercentageText>
          <OfUsersText x="50%" dy="10px">
            of users
          </OfUsersText>
        </text>
      </svg>
    </FlexWrapper>
  );
};

export default ProgressCircle;
