import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import nav from '../../modules/Full/_nav';
import { ChevronLeftIcon } from 'merchant-portal-components';

const BackButton = styled.div`
  width: 176px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4px 300px;
  grid-gap: 10px;
  align-items: center;
  margin-top: 20px;

  &: hover {
    color: #3051d4;
  }
`;

const BreadCrumbWrapper = styled.div`
  color: ${(props) => props.theme.darkBlue};
  margin: ${(props) => (props.style ? props.style.margin : '30px')};
  margin-left: 0;
  @media print {
    display: none;
  }
`;

const PageHeader = styled.h2`
  font-weight: 700;
  font-size: 36px;
  overflow-wrap: break-word;
`;

const RootLink = styled(Link)`
  margin: 0px;
  padding: 0px;
  color: black;
  font-size: 16px;

  &: hover {
    color: #3051d4;
  }
  /* padding-right: 4px;
  
  color: ${(props) => props.theme.blue}; */
`;

const RootItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

// const ChevronWrapper = styled.span`
//   line-height: 16px;
// `;

const BreadCrumb = ({ style, ...props }) => {
  const hasNumber = (myString) => /\d/.test(myString);

  const getPaths = () => {
    let paths = window.location.pathname;
    const items = [];
    paths
      .replace(/\$/, '')
      .split('/')
      .reduce((prev, cur) => {
        const pathSection = `${prev}/${cur}`;

        if (!hasNumber(pathSection)) {
          nav.items.forEach((mainItem) => {
            if (mainItem.url == pathSection) {
              items.push({
                name: mainItem.name,
                url: mainItem.url,
                abstractUrl: mainItem.url,
              });
            } else if (mainItem.children) {
              mainItem.children.forEach((childItem) => {
                if (childItem.url == pathSection) {
                  items.push({
                    name: childItem.name,
                    url: childItem.url,
                    abstractUrl: childItem.url,
                  });
                }
              });
            } else if (mainItem.notOnSidebar) {
              mainItem.notOnSidebar.forEach((notSidebarChild) => {
                if (notSidebarChild.url == pathSection) {
                  items.push({
                    name: notSidebarChild.name,
                    url: notSidebarChild.url,
                    abstractUrl: notSidebarChild.url,
                  });
                }
              });
            }
          });
        } else {
          nav.items.forEach((mainItem) => {
            if (
              mainItem.url.includes(items[items.length - 1].abstractUrl + '/:')
            ) {
              items.push({
                name: mainItem.name,
                url: pathSection,
                abstractUrl: mainItem.url,
              });
            }
            if (mainItem.children) {
              mainItem.children.forEach((childItem) => {
                if (
                  childItem.url.includes(
                    items[items.length - 1].abstractUrl + '/:'
                  )
                ) {
                  items.push({
                    name: childItem.name,
                    url: pathSection,
                    abstractUrl: childItem.url,
                  });
                }
              });
            }
            if (mainItem.notOnSidebar) {
              mainItem.notOnSidebar.forEach((notSidebarChild) => {
                if (
                  notSidebarChild.url.includes(
                    items[items.length - 1].abstractUrl + '/:'
                  )
                ) {
                  items.push({
                    name: notSidebarChild.name,
                    url: pathSection,
                    abstractUrl: notSidebarChild.url,
                  });
                }
              });
            }
          });
        }
        return pathSection;
      });
    return items;
  };

  let paths = getPaths();
  let currentPage = paths.pop();
  return (
    <BreadCrumbWrapper style={style}>
      <RootItemWrapper>
        {paths.map((path, index) => (
          <BackButton key={index}>
            <ChevronLeftIcon />
            <RootLink to={path.url}>{path.name}</RootLink>
          </BackButton>
        ))}
      </RootItemWrapper>

      <PageHeader>{props.pageName || currentPage.name}</PageHeader>
    </BreadCrumbWrapper>
  );
};

export default BreadCrumb;
