import React from 'react';
import styled from 'styled-components';
import Fade from '@material-ui/core/Fade';
import { Button } from 'merchant-portal-components';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1202;
`;

const Wrapper2 = styled.div`
  position: fixed;
  background-color: #f1f0f5;
  width: 100%;
  height: 75px;
  //   border-bottom: solid 1px #d3d3d3;
`;

const Wrapper3 = styled.div`
  margin: 14px auto;
  width: 70%;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 50px;
  margin: auto;
  align-items: center;
`;

const PortalReload = ({ isVisible }) => {
  return (
    <Fade in={isVisible} timeout={{ enter: 400, exit: 1000 }}>
      <Wrapper isVisible={isVisible}>
        <Wrapper2>
          <Wrapper3>
            <ButtonsWrapper>
              <div>
                We just released a new version of the Portal. Please reload the
                page for the changes to take place.
              </div>
              <Button onClick={() => window.location.reload()}>Reload</Button>
            </ButtonsWrapper>
          </Wrapper3>
        </Wrapper2>
      </Wrapper>
    </Fade>
  );
};

export default PortalReload;
