import styled from 'styled-components';

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${props => '#606672'};
  text-transform: uppercase;
`;

export default Label;
