import React from 'react';
import {DashedRow} from './components/AppFeedElements';
import styled from 'styled-components';

const FeedButton = styled.div`
  color: ${props => props.theme.darkBlue};
  margin: auto;
  font-weight: 600;
`;

const DashedCreateRowButton = ({openCreateFeedRowForm}) => (
  <DashedRow onClick={openCreateFeedRowForm}>
    <FeedButton>+ CREATE NEW FEED ROW </FeedButton>
  </DashedRow>
);

export default DashedCreateRowButton;
