import React from 'react';
import { Modal } from 'merchant-portal-components';
import { ButtonRow, Button } from 'merchant-portal-components';
import styled from 'styled-components';

const ModalBody = styled.div`
  width: 400px;
  background: white;
  padding: 16px;
  display: grid;
  grid-gap: 24px;
  border-radius: 2px;
  p,
  li {
    font-size: 14px;
  }
`;

const BusinessesName = styled.ul`
  list-style: disc;
  width: 400;
  padding: 16px;
`;

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
  font-size: 20px;
  letter-spacing: 0.03px;
  padding: 16px;
`;

const ConfirmOverrides = ({ businesses, onCancel, onConfirm }) => {
  return (
    <Modal borderRadius>
      <ModalTitle>
        <div>Confirm overrides</div>
        <div style={{ cursor: 'pointer' }} onClick={onCancel}>
          x
        </div>
      </ModalTitle>
      <ModalBody>
        {businesses.length ? (
          <div>
            <p>
              The following locations have existing overrides that will be
              replaced:
            </p>
            <BusinessesName>
              {businesses.map((business, index) => {
                return <li key={index}>{business.display_name}</li>;
              })}
            </BusinessesName>
          </div>
        ) : (
          <p>New prices will be updated to selected locations!</p>
        )}
        <ButtonRow justifyContent="space-between">
          <Button small type="button" onClick={onCancel} secondary>
            Cancel
          </Button>
          <Button small type="button" onClick={onConfirm}>
            Save
          </Button>
        </ButtonRow>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmOverrides;
