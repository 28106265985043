import React, {Fragment} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import GoogleMapsWithOverlayZones from '../../../../../../GoogleMapsWithOverlayZones/GoogleMapsWithOverlayZones';
import {
  ControlShowShapesContainer,
  ShapeNameGrid,
  ShapeName,
  SubHeaderWrapper,
  InteractWithGoogleMapsGridContainer,
  StyledP
} from '../../common/styledComponents';

const BusinessLocationDeliveryZonesCreateShape = props => {
  const {
    deliveryZoneObjects,
    handleDeliveryZoneVisibility,
    editingColour,
    locationCoords
  } = props;

  return (
    <Fragment>
      <SubHeaderWrapper>
        <StyledP semiBold size="18px">
          Draw Delivery Zone
        </StyledP>
      </SubHeaderWrapper>
      <InteractWithGoogleMapsGridContainer>
        <ControlShowShapesContainer>
          <StyledP padding="10px 0 0 0" semiBold>
            Show Other Zones:
          </StyledP>
          {deliveryZoneObjects.map((deliveryZone, index) => (
            <ShapeNameGrid key={index}>
              <Checkbox
                color="primary"
                checked={deliveryZone.isVisible}
                onChange={event =>
                  handleDeliveryZoneVisibility(deliveryZone, event)
                }
              />
              <ShapeName>
                <StyledP semiBold isVisible={deliveryZone.isVisible}>
                  {deliveryZone.zoneName}
                </StyledP>
              </ShapeName>
            </ShapeNameGrid>
          ))}
        </ControlShowShapesContainer>
        {deliveryZoneObjects ? (
          <GoogleMapsWithOverlayZones
            overlayObjects={deliveryZoneObjects}
            maxDrawnOverlaysAllowed={1}
            editingColour={editingColour}
            allowDrawing
            center={locationCoords}
            selectedLocations={[locationCoords]}
            zoom={15}
          />
        ) : null}
      </InteractWithGoogleMapsGridContainer>
    </Fragment>
  );
};

export default BusinessLocationDeliveryZonesCreateShape;
