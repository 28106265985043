const getExistingLocations = (
  selectedLocations,
  summaryData,
  initialValues
) => {
  let businessIds = summaryData.reduce(
    (acc, data) => [...acc, ...data.businesses.map((business) => business.id)],
    []
  );

  return selectedLocations.filter(
    (location) =>
      businessIds.includes(location.id) &&
      !initialValues.assignedBusinesses.includes(location.id)
  );
};

export default getExistingLocations;
