export const findMaxValue = params => {
  var paramsCount = params.length;
  var maxValue = Math.max(...params);
  var stepSize = Math.ceil(maxValue / paramsCount);
  var suggestedMax = maxValue + stepSize;
  return suggestedMax;
};

export const findStepSize = params => {
  var paramsCount = params.length;
  var maxValue = Math.max(...params);
  var stepSize = Math.ceil(maxValue / paramsCount);

  if (stepSize > 50 && stepSize % 50 != 0)
    stepSize = stepSize - (stepSize % 50);

  return stepSize;
};
