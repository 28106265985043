import moment from 'moment';
import flat from 'flat';

export const STRING = "string";
export const  BOOLEAN = "boolean";
export const DATE = "date";
export const NUMBER = 'number';

export const sort = (sortList, sortArgs) => {
    const flattenSortList = sortList.map( item => flat(item));
    return sortArgs.sortMethod(flattenSortList, sortArgs);
}

export const sortByTypes = (sortList, sortArgs) => {
    const {sortType, value, sortOrder} = sortArgs;
    const sortByKey = value;
    const sortedFinalList = sortList.sort((list1, list2) => {
        let result;
        switch(sortType) {
            case STRING:
                result = list1[sortByKey].toLowerCase() > list2[sortByKey].toLowerCase();
                break;
            case DATE:
                const listVal1 = (list1[sortByKey])? 
                                    moment(list1[sortByKey]).format("YYYY-MM-DD") 
                                    : moment().format("YYYY-MM-DD");
                const listVal2 = (list2[sortByKey])? 
                                    moment(list2[sortByKey]).format("YYYY-MM-DD") 
                                    : moment().format("YYYY-MM-DD");
                result = listVal1 > listVal2;
                break;
            case BOOLEAN:
                result = list1[sortByKey] < list2[sortByKey];
                break;
            default:
                result = list1[sortByKey] > list2[sortByKey];
                break;
        }
        if(sortOrder == 'desc') {
            return result ? -1 : 1;
        }else{
            return result ? 1 : -1;
        }
      });
    return sortedFinalList.map( item => flat.unflatten(item));
}

