import {REQUEST_BATCH_DETAIL, RECEIVE_BATCH_DETAIL} from './types';

export default function(
  state = {
    isFetchingBatchDetail: false,
    response: null
  },
  action
) {
  switch (action.type) {
    case REQUEST_BATCH_DETAIL:
      return {...state, isFetchingBatchDetail: true};
    case RECEIVE_BATCH_DETAIL:
      return {
        ...state,
        isFetchingBatchDetail: false,
        response: action.payload.data
      };
    default:
      return state;
  }
}
