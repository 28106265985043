import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { SmallToggleSwitch } from '../../../../../components/ToggleSwitch/ToggleSwitch';
import { RenderSelect } from '../../../../../components/FormElements/FormikElements';
import { Field } from 'formik';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
  align-items: center;
`;

const SegmentSelection = ({ setFieldValue, allSegments, segments }) => {
  const [isChecked, setIsChecked] = useState(!segments.length);

  return (
    <Wrapper>
      <SmallToggleSwitch
        checked={isChecked}
        label="All Segments"
        onChange={e => {
          setFieldValue('segments', []);
          setIsChecked(e.target.checked);
        }}
      />
      {!isChecked && (
        <Field
          name="segments"
          component={RenderSelect}
          options={allSegments}
          placeholder="Segments"
          isMulti
          options={allSegments ? allSegments : []}
          getOptionLabel={segment => segment.name}
          getOptionValue={segment => segment}
          simpleValue
          valueKey="segment_id"
        />
      )}
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  allSegments: state.segments.allSegments
});

export default connect(mapStateToProps)(SegmentSelection);
