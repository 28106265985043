import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import GoogleMapsWithOverlayZones from '../../../../GoogleMapsWithOverlayZones/GoogleMapsWithOverlayZones';
import LocationsTargeted from '../../LocationsTargeted/LocationsTargeted';
import { Field } from 'redux-form';
import Radio from '@material-ui/core/Radio';
import { Select } from 'merchant-portal-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  renderTextField,
  renderRadioGroup,
  renderSelect,
} from '../../../../../components/FormElements/ReduxForm';
import { scroller } from 'react-scroll';
import SegmentCard, { ButtonRow } from '../../SegmentCard/SegmentCard';
import { Button } from 'merchant-portal-components';
import Collapse from '@material-ui/core/Collapse';
import { SmallToggleSwitch } from '../../../../../components/ToggleSwitch/ToggleSwitch';
import ErrorMessage from '../../../../../components/ErrorMessage/ErrorMessage';
import { BoldCapitalizedLabel } from 'merchant-portal-components';

const RadioField = styled(Field)`
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin: 40px 0;
`;

export class BuildYourAudience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOverlayError: false,
      showFilterButtons: false,
      previewBusinesses: [],
    };
  }

  componentDidMount() {
    if (this.props.clone) {
      this.props.change('name', this.props.nameToClone + ' copy');
    }
    if (this.props.audienceZoneValue !== 'all_users') {
      this.showFilterButtons();
    }
  }

  validateMap() {
    this.setState({ showOverlayError: true });
  }

  scrollToMap() {
    scroller.scrollTo('user-selection', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  }

  scrollToName() {
    scroller.scrollTo('scrollToName', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  }

  getBusinesses = (businesses) => {
    return businesses.map((business) => ({
      label: business.name,
      value: business.id,
    }));
  };

  handlePreviewBusinessesChange = (previewBusinesses) => {
    this.setState({ previewBusinesses });
  };

  renderNextButton() {
    if (
      this.props.segmentForm &&
      this.props.segmentForm.syncErrors &&
      this.props.segmentForm.syncErrors.name
    ) {
      return (
        <Button
          disabled={!this.props.valid}
          type="submit"
          style={{ marginLeft: 'auto' }}
          onClick={this.scrollToName}
        >
          Next
        </Button>
      );
    } else if (
      this.props.audienceZoneValue !== 'all_users' &&
      !this.props.drawnOverlayZones.length &&
      !Object.keys(this.props.editOverlayObj).length
    ) {
      return (
        <Button
          type="button"
          onClick={() => this.validateMap()}
          style={{ marginLeft: 'auto' }}
        >
          Next
        </Button>
      );
    }
    return (
      <Button
        disabled={!this.props.valid}
        type="submit"
        style={{ marginLeft: 'auto' }}
      >
        Next
      </Button>
    );
  }

  showFilterButtons = () => {
    this.setState({ showFilterButtons: true });
  };

  hideFilterButtons = () => {
    this.setState({ showFilterButtons: false });
  };

  toggleFilterButtons = () => {
    if (this.state.showFilterButtons) {
      this.hideFilterButtons();
      this.props.change('audience.zone', 'all_users');
    } else {
      this.showFilterButtons();
      this.props.change('audience.zone', 'last_located_in_zone');
    }
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div id="scrollToName" />
        <SegmentCard
          title="build your audience"
          cancelEditSegment={this.props.cancelEditSegment}
        >
          <div>
            <div id="user-selection" style={{ width: '300px' }}>
              <Field
                name="name"
                label="Segment Name*"
                component={renderTextField}
              />
            </div>
            <BoldCapitalizedLabel>
              Choose Your Target Audience
            </BoldCapitalizedLabel>
            <SmallToggleSwitch
              name="audience.zone"
              label={'All Users'}
              onChange={() => this.toggleFilterButtons()}
              checked={!this.state.showFilterButtons}
            />
            <Collapse in={this.props.audienceZoneValue !== 'all_users'}>
              <RadioField name="audience.zone" component={renderRadioGroup}>
                <FormControlLabel
                  value="last_located_in_zone"
                  label="Users last located inside this zone"
                  control={<Radio />}
                />
                <FormControlLabel
                  value="last_located_outside_zone"
                  label="Users last located outside this zone"
                  control={<Radio />}
                />
                <FormControlLabel
                  value="live_in_zone"
                  label="Users with a delivery address in this zone"
                  control={<Radio />}
                />
                <FormControlLabel
                  value="live_outside_zone"
                  label="Users with a delivery address outside this zone"
                  control={<Radio />}
                />
                {/* <FormControlLabel
                  value="purchase_in_zone"
                  label="Have made purchases in this zone"
                  control={<Radio />}
                />
                <FormControlLabel
                  value="purchase_outside_zone"
                  label="Have made purchases outside this zone"
                  control={<Radio />}
                /> */}
              </RadioField>
              {/* <SelectWrapper>
                <div>Select Locations</div>
                <div>
                  <Field
                    isMulti
                    topMod={-16}
                    zIndex={9}
                    component={renderSelect}
                    value={this.state.previewBusinesses}
                    getOptionValue={business => business.value}
                    getOptionLabel={business => business.label}
                    name="businesses"
                    options={this.getBusinesses(this.props.businesses)}
                    valueKey="id"
                  />
                </div>
              </SelectWrapper> */}
              <div>
                <div>
                  Draw an area on the map you want to use as your target
                  audience by clicking one of the shape options below.
                </div>
                <GoogleMapsWithOverlayZones
                  zoom={4}
                  isSearchEnabled={true}
                  overlayObjects={
                    this.props.overlay ? [this.props.overlay] : null
                  }
                  maxDrawnOverlaysAllowed={1}
                  allowDrawing={true}
                  editingColour={'#00A5DB'}
                  selectedLocations={this.props.allLocations.filter(
                    (location) => location.lat && location.lng
                  )}
                />
                {this.state.showOverlayError &&
                  !this.props.drawnOverlayZones.length &&
                  !Object.keys(this.props.editOverlayObj).length && (
                    <ErrorMessage>
                      Draw a shape or choose 'All Users' under 'Choose Your
                      Target Audience'.
                    </ErrorMessage>
                  )}
              </div>
            </Collapse>
          </div>

          {/* {this.props.audienceZoneValue !== 'all_users' && (
            <LocationsTargeted
              selectedLocations={this.props.selectedLocations}
              drawnOverlayZones={this.props.drawnOverlayZones}
            />
          )} */}

          <ButtonRow>
            <Link to="/segments">
              <Button
                type="button"
                secondary
                onClick={() => this.props.cancelEditSegment()}
              >
                Cancel
              </Button>
            </Link>
            {this.renderNextButton()}
          </ButtonRow>
        </SegmentCard>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    drawnOverlayZones: state.googleMapsWithOverlayZones.drawnOverlayZones,
    editOverlayObj: state.googleMapsWithOverlayZones.editOverlayObj,
    businesses: state.businessLocationsList.businessLocationsOfMerchant,
  };
};

export default connect(mapStateToProps, null)(BuildYourAudience);
