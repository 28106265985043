import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';
import styled from 'styled-components';
import CustomerSearchManager from '../../../../../CustomerSearchManager/index';
import { Title, ModalBody } from 'merchant-portal-components';

const HorizontalLine = styled.hr`
  width: 100%;
  border: 0;
  border-top: 2px solid #d8d8d8;
  opacity: 0.4;
`;

const Content = styled.div`
  padding: 0 150px;
`;

const Notes = styled.div`
  font-weight: 600;
  padding: 0 0 10px 0;
`;

const RewardsForm = styled.div`
  padding: 20px 0 0 0;
`;

const TypeAndAmountWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

const RewardType = styled.p`
  font-weight: 600;
  padding: 10px;
`;

const NotesFieldWrapper = styled.div`
  padding: 30px 0;
`;

const AreYouSureMessage = styled.div`
  text-align: center;
  color: ${(props) => props.theme.darkBlue};
  font-weight: 600;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  padding: 30px 0 0 0;
`;

const NevermindWrapper = styled.div`
  text-align: right;
`;

export let SendTestMessageModal = (props) => {
  const { message } = props;

  const { isTesting } = props.mobileMessagesReducer;

  if (props.mobileMessagesReducer.testSuccess) {
    props.closeModal();
  }
  return (
    <Modal data-test="component-Modal">
      <div className="modal">
        <ModalBody padding="30px 0 40px 0">
          <Title>Send Test Message</Title>
          <HorizontalLine />
          <Content>
            <Notes data-test="notes-customerName">
              You are about to send the message " {message.name} "
            </Notes>
            <RewardsForm>
              <TypeAndAmountWrapper>
                <div>
                  <strong>Message: </strong> {message.message}
                </div>
              </TypeAndAmountWrapper>
              <NotesFieldWrapper>
                <p style={{ marginBottom: '16px' }}>
                  *Customer to send a test {message.type} message to
                </p>
                <CustomerSearchManager />
              </NotesFieldWrapper>
            </RewardsForm>
            <AreYouSureMessage>
              The message will be sent now and cannot be undone.
            </AreYouSureMessage>
            <ButtonsWrapper>
              <NevermindWrapper>
                <Button
                  data-test="button-nevermind"
                  type="button"
                  secondary
                  onClick={props.closeModal}
                >
                  CANCEL
                </Button>
              </NevermindWrapper>
              <div>
                <Button
                  data-test="button-send"
                  type="button"
                  onClick={() =>
                    props.handleSendTestMessage(
                      message,
                      props.customerSearchManager.selectedCustomer.customer_id
                    )
                  }
                  disabled={
                    props.customerSearchManager.selectedCustomer ? false : true
                  }
                >
                  {isTesting ? 'Testing...' : ' Send'}
                </Button>
              </div>
            </ButtonsWrapper>
          </Content>
        </ModalBody>
      </div>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    customerSearchManager: state.customerSearchManager,
    mobileMessagesReducer: state.mobileMessagesReducer,
  };
}

export default connect(mapStateToProps, {})(SendTestMessageModal);
