import {
    GET_APP_INTGERATION_CONFIG_SCHEMA,
    UPDATE_APP_INTEGRATION_CONFIG
  } from './AppIntegrationTypes';

const initialState = {
    appIntegration: [],
    appIntegrationPatchSchema: ['type', 'provider', 'configuration'],
    requestingAppIntegration: true,
    delimiter: "|"
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_APP_INTGERATION_CONFIG_SCHEMA:
          return {
            ...state,
            requestingAppIntegration: false,
            appIntegration: action.appIntegration
          };
        case UPDATE_APP_INTEGRATION_CONFIG:
          return {
            ...state,
            appIntegration: {
              ...state.appIntegration,
              [action.updatedAppIntegration.type]: action.updatedAppIntegration
            },
            requestingAppIntegration: false
          };
        default:
          return state;
      };
}