import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { Icon } from 'merchant-portal-components';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const FieldWrappedDiv = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 70px 1fr;
  background-color: white;
  padding: 10px 15px 10px 0px;
  border-bottom: 1px solid #e1e1e1;
`;

const CategoryCardDiv = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
    width: 100px;
    max-height: 64px;
    min-height: 64px;
  }
`;

const HeaderDiv = styled.div`
  display: grid;
  background-color: white;
  grid-template-columns: 70px auto 60px;
  padding: 0 10px 25px 10px;
  font-weight: bold;
  // border-top-left-radius: 12px;
  // border-top-right-radius: 12px;
`;

const HeaderText = styled.div`
  width: 100%;
  margin: 10px 0px;
  font-size: 16px;
  color: #191818;
  width: 100%;
  margin: 10px 0px;
  font-size: 16px;
  color: #191818;
  padding: 15px 25px;
  padding-top: 0px;
  border-bottom: 1px solid #e1e1e1;
`;

const StyledSortableContainer = styled.div`
  height: 100%;
  overflow-y: scroll; // need to be in container for auto scroll when sorting
  border-radius: 12px;
`;

const styles = {
  switchBase: {
    transition: 'none',
  },
  switchBaseDefault: {
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
};

const SortableItem = SortableElement(
  ({
    category,
    categoryIndex,
    index,
    arrayHelpers,
    isSorting,
    classes,
    formTypeName,
    formHeadName,
  }) => {
    return (
      <FieldWrappedDiv>
        <Icon
          className="far fa-equals"
          style={{ cursor: 'move', textAlign: 'center' }}
        />
        <CategoryCardDiv
          name={`${formTypeName}[${categoryIndex}]`}
          style={{ cursor: 'pointer' }}
        >
          <img
            name={`${formTypeName}[${categoryIndex}].image_url`}
            height="78"
            src={category.image_url}
          />
          <div name={`${formTypeName}[${categoryIndex}].name`}>
            {category.name}
          </div>
          <div style={{ textAlign: 'right' }}></div>
        </CategoryCardDiv>
      </FieldWrappedDiv>
    );
  }
);

const SortableList = SortableContainer(
  ({ categories, arrayHelpers, isSorting, classes, formHeadName }) => {
    return (
      <StyledSortableContainer>
        <HeaderText>
          Reorder the position that products in this <span>{formHeadName}</span>{' '}
          will be presented to customers on your app or web
        </HeaderText>
        {categories?.map((category, index) => (
          <SortableItem
            key={`category-${index}`}
            index={index}
            isSorting={isSorting}
            categoryIndex={index}
            classes={classes}
            category={category}
            arrayHelpers={arrayHelpers}
          />
        ))}
      </StyledSortableContainer>
    );
  }
);

const RenderProductList = ({
  sortCategories,
  sortProducts,
  arrayHelpers,
  classes,
  formHeadName,
}) => {
  const [isSorting, setIsSorting] = useState(false);
  const categories = arrayHelpers.form.values.categories;
  const setFieldValue = arrayHelpers.form.setFieldValue;

  return (
    <Fragment>
      <SortableList
        helperClass="sortableHelper"
        formHeadName={formHeadName}
        classes={classes}
        categories={categories}
        sortCategories={sortCategories}
        sortProducts={sortProducts}
        arrayHelpers={arrayHelpers}
        isSorting={isSorting}
        onSortStart={() => setIsSorting(true)}
        onSortEnd={({ oldIndex, newIndex }) => {
          const newList = arrayMove(categories, oldIndex, newIndex);
          setFieldValue(`categories`, newList);
          setTimeout(() => setIsSorting(false), 250);
        }}
        distance={1}
      />
    </Fragment>
  );
};

export default withStyles(styles)(RenderProductList);
