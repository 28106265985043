import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Icon } from 'merchant-portal-components';

export const ButtonRow = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  grid-column: 1 / -1;
`;

const SegmentCardWrapper = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  margin: 30px 0;
  padding: 30px;
  background: ${(props) => props.theme.white};
  @media (max-width: 992px) {
    padding: 15px;
  }
  position: relative;
  display: grid;
  grid-template-rows: auto auto auto;
  // grid-template-columns: ${(props) =>
    props.singleColumn ? '1fr' : '1fr 240px'};
  grid-gap: 10px;
`;

const TitleWrapper = styled.div`
  grid-column: 1 / -1;
`;

const Title = styled.h3`
  color: ${(props) => props.theme.darkBlue};
  font-weight: 800;
  text-transform: uppercase;
`;

const Instructions = styled.p`
  color: ${(props) => props.theme.darkBlue};
  margin-top: 5px;
`;

const CloseButtonWrapper = styled(Link)`
  position: absolute;
  top: 4px;
  right: 8px;
`;

const SegmentCard = ({
  title,
  instructions,
  singleColumn,
  children,
  cancelEditSegment,
}) => (
  <SegmentCardWrapper instructions={instructions} singleColumn={singleColumn}>
    <TitleWrapper>
      <Title>{title}</Title>
      <Instructions>{instructions}</Instructions>
    </TitleWrapper>
    {children}
  </SegmentCardWrapper>
);

export default SegmentCard;
