import _nav from '../_nav';
import { getClientTiers } from './clientPackageRepo';
import { allTiers, pageLevelExemptions } from './clientPackageRepo';

export let authorizedPathsForTiers = [];

const isPageLevelExemptions = (appIdentifier, url) => {
  return (
    pageLevelExemptions &&
    pageLevelExemptions[appIdentifier] &&
    pageLevelExemptions[appIdentifier].includes(url)
  );
};

const isAuthorizedByClientTier = (clientTier, menuDisableDisplay) => {
  if (menuDisableDisplay) {
    return !clientTier.some((tier) => menuDisableDisplay.includes(tier));
  }
  return true;
};

const isAuthorizedByUserRole = (navRoles, userRole) => {
  if (navRoles) {
    const intersection = userRole.filter((element) =>
      navRoles.includes(element)
    );
    return intersection.length > 0;
  }
  return true;
};

const isVisible = (clientTier, menuHideDisplay) => {
  if (menuHideDisplay) {
    const intersection = clientTier.filter((element) =>
      menuHideDisplay.includes(element)
    );
    return !(intersection.length > 0);
  }
  return true;
};

const getSubNavItems = (appIdentifier, clientTier, subItems) => {
  let subNavItems = [];
  subItems.map((subitem) => {
    subitem['disabled'] = true;
    if (
      isAuthorizedByClientTier(clientTier, subitem.disableForTiers) ||
      isPageLevelExemptions(appIdentifier, subitem.url)
    ) {
      subitem['disabled'] = false;
      authorizedPathsForTiers.push(subitem.url);
    }
    if (
      isVisible(clientTier, subitem.hideDisplay) &&
      isAuthorizedByUserRole(subitem.authorization, [
        localStorage.getItem('role'),
      ])
    ) {
      subNavItems.push(subitem);
    }
  });
  return subNavItems;
};

const filteredNav = (appIdentifier, appConfig) => {
  if (appIdentifier) {
    const clientTier = getClientTiers(appIdentifier, appConfig);
    let newNavItems = [];
    let clonedNav = JSON.parse(JSON.stringify(_nav.items));
    clonedNav.map((item) => {
      //parent nav
      item['disabled'] = true;
      if (
        isAuthorizedByClientTier(clientTier, item.disableForTiers) ||
        isPageLevelExemptions(appIdentifier, item.url)
      ) {
        item['disabled'] = false;
        authorizedPathsForTiers.push(item.url);
      }

      //sub nav
      if (item.children) {
        item.children = getSubNavItems(
          appIdentifier,
          clientTier,
          item.children
        );
      }

      // not on side bar
      if (item.notOnSidebar) {
        item.notOnSidebar = getSubNavItems(
          appIdentifier,
          clientTier,
          item.notOnSidebar
        );
      }

      if (
        isAuthorizedByUserRole(item.authorization, [
          localStorage.getItem('role'),
        ])
      ) {
        newNavItems.push(item);
      }
    });
    return { items: newNavItems };
  }

  return { items: _nav.items };
};

export default filteredNav;
