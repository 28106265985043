import React from 'react';
import { Button, ButtonRow, LoadingButton } from 'merchant-portal-components';

const ExportCSVButton = (props) => {
  const { requestParams, requestingCSV, exportCSV } = props;
  return (
    <ButtonRow justifyContent="flex-end">
      {requestingCSV ? (
        <LoadingButton alignRight disabled>
          Export Transactions
        </LoadingButton>
      ) : (
        <Button
          alignRight
          onClick={() =>
            exportCSV({
              requestPath: 'batch_detail',
              fileName: 'transaction-export',
              requestParams,
            })
          }
        >
          Export Transactions
        </Button>
      )}
    </ButtonRow>
  );
};

export default ExportCSVButton;
