import api from '../../../api';
import {REQUEST_BATCH_DETAIL, RECEIVE_BATCH_DETAIL} from './types';

function requestBatchDetail() {
  return {
    type: REQUEST_BATCH_DETAIL
  };
}

export function fetchBatchDetail(params) {
  return function(dispatch) {
    dispatch(requestBatchDetail());
    return api
      .get(`/batch_detail?batch_ids=${params.batch_ids}`)
      .then(response => {
        dispatch({type: RECEIVE_BATCH_DETAIL, payload: response.data});
      })
      .catch(error => {
        // console.log(error);
      });
  };
}
