import React from 'react';
import styled from 'styled-components';
import CircleCheck from './CircleCheck';
import {PlusIcon, MinusIcon} from './Icons';

const SubItemRow = styled.div`
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${props => '#606672'};
  text-transform: capitalize;
`;

const QuantitySection = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 20px;
`;

const SubItemRowComponent = ({
  option,
  item,
  subItem,
  subItemsById,
  incrementSubItem,
  decrementSubItem
}) => {
  const isBoolean = subItem.maximum_pick === 1;

  const handleSubItemClick = () => {
    if (subItem.maximum_pick === 1 || option.maximum_pick === 1) {
      item.sub_items.forEach(subItemId => {
        const subItemInItem = subItemsById[subItemId];
        if (subItemInItem.quantity) {
          decrementSubItem(subItemInItem);
        }
        if (!subItem.quantity && subItem.item_id === subItemId) {
          incrementSubItem(subItem);
        }
      });
    }
  };

  const handleIncrement = () => {
    if (subItem.quantity) {
      incrementSubItem(subItem);
    } else {
      item.sub_items.forEach(subItemId => {
        const subItemOfItem = subItemsById[subItemId];
        if (subItemOfItem.quantity) {
          decrementSubItem(subItemOfItem, 0);
        }
      });
      incrementSubItem(subItem);
    }
  };

  return (
    <SubItemRow onClick={handleSubItemClick}>
      {subItem.name.toLowerCase()}
      {subItem.quantity === 1 && isBoolean && <CircleCheck />}
      {subItem.maximum_pick > 1 && option.maximum_pick !== 1 && (
        <QuantitySection>
          <div
            onClick={() => {
              if (subItem.quantity) {
                decrementSubItem(subItem);
              }
            }}
          >
            <MinusIcon />
          </div>
          {subItem.quantity}
          <div onClick={handleIncrement}>
            <PlusIcon />
          </div>
        </QuantitySection>
      )}
    </SubItemRow>
  );
};

export default SubItemRowComponent;
