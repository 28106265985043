import React from 'react';
import styled from 'styled-components';
import SingleFeedComponent from './SingleFeedComponent';
import { LoadingBlueLarge } from 'merchant-portal-components';

const Wrapper = styled.div`
  :hover {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.18);
  }
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
  padding: 24px;
  margin-bottom: 24px;
`;

const FeedLayoutList = ({
  list,
  deepDuplicateFeed,
  deleteAppFeedLayout,
  editAppFeedLayout,
  appIdentifier,
  history,
}) => {
  const items =
    list.search.text || list.search.filterAttribute
      ? list.search.list
      : list.listAppFeed;
  return list.listAppFeedLayoutIsRequesting ? (
    <div>
      <LoadingBlueLarge />
    </div>
  ) : (
    <div>
      {items.map((singleAppFeed) => (
        <Wrapper
          style={{ cursor: 'pointer' }}
          key={singleAppFeed.id}
          onClick={() => editAppFeedLayout(singleAppFeed.id)}
        >
          <SingleFeedComponent
            key={singleAppFeed.id}
            singleAppFeed={singleAppFeed}
            deepDuplicateFeed={deepDuplicateFeed}
            deleteAppFeedLayout={deleteAppFeedLayout}
            appIdentifier={appIdentifier}
            history={history}
          />
        </Wrapper>
      ))}
    </div>
  );
};

export default FeedLayoutList;
