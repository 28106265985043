import React, {Component} from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipIcon } from '../../../../components/Icon';

class SummaryBar extends Component {
  state = {};

  render() {
    var {data} = this.props;
    return (
      <Summary>
        <UsersData>
          Active Users {' '}
          <Tooltip title="The number of users who have accessed the app" placement="top" arrow>
            <span> <TooltipIcon/> </span>
          </Tooltip>
          <UsersNumber>
            {data.active_user_counts.total.toLocaleString()}
          </UsersNumber>
          {data.active_user_counts.percent_change !== 0 &&
            (data.active_user_counts.percent_change > 0 ? (
              <PercentageUp>
                <i className="fa fa-arrow-up" />{' '}
                {data.active_user_counts.percent_change.toLocaleString()}%
              </PercentageUp>
            ) : (
              <PercentageDown>
                <i className="fa fa-arrow-down" />{' '}
                {Math.abs(
                  data.active_user_counts.percent_change
                ).toLocaleString()}
                %
              </PercentageDown>
            ))}
          {data.active_user_counts.percent_change !== 0 && (
            <CompareText>vs previous period</CompareText>
          )}
        </UsersData>
        <UsersData>
          New Sign Ups {' '}
          <Tooltip title="The number of newly registered users" placement="top" arrow>
            <span> <TooltipIcon/> </span>
          </Tooltip>
          <UsersNumber>
            {data.new_user_sign_up_counts.total.toLocaleString()}
          </UsersNumber>
          {data.new_user_sign_up_counts.percent_change !== 0 &&
            (data.new_user_sign_up_counts.percent_change > 0 ? (
              <PercentageUp>
                <i className="fa fa-arrow-up" />{' '}
                {data.new_user_sign_up_counts.percent_change.toLocaleString()}%
              </PercentageUp>
            ) : (
              <PercentageDown>
                <i className="fa fa-arrow-down" />{' '}
                {Math.abs(
                  data.new_user_sign_up_counts.percent_change
                ).toLocaleString()}
                %
              </PercentageDown>
            ))}
          {data.new_user_sign_up_counts.percent_change !== 0 && (
            <CompareText>vs previous period</CompareText>
          )}{' '}
        </UsersData>
        <UsersData>
          First Time Users {' '}
          <Tooltip title="The number of users who have made their first purchase" placement="top" arrow>
            <span> <TooltipIcon /> </span>
          </Tooltip>
          <UsersNumber>
            {data.first_time_user_counts.total.toLocaleString()}
          </UsersNumber>
          {data.first_time_user_counts.percent_change !== 0 &&
            (data.first_time_user_counts.percent_change > 0 ? (
              <PercentageUp>
                <i className="fa fa-arrow-up" />{' '}
                {data.first_time_user_counts.percent_change.toLocaleString()}%
              </PercentageUp>
            ) : (
              <PercentageDown>
                <i className="fa fa-arrow-down" />{' '}
                {Math.abs(
                  data.first_time_user_counts.percent_change
                ).toLocaleString()}
                %
              </PercentageDown>
            ))}
          {data.first_time_user_counts.percent_change !== 0 && (
            <CompareText>vs previous period</CompareText>
          )}{' '}
        </UsersData>
        <UsersData>
          New User Conversion {' '}
          <Tooltip title="The rate of how many of the newly signed users, made their first purchase" placement="top" arrow>
            <span> <TooltipIcon /> </span>
          </Tooltip>
          <UsersNumber>{data.conversion.total.toLocaleString()}%</UsersNumber>
          {data.conversion.percent_change !== 0 &&
            (data.conversion.percent_change > 0 ? (
              <PercentageUp>
                <i className="fa fa-arrow-up" />{' '}
                {data.conversion.percent_change.toLocaleString()}%
              </PercentageUp>
            ) : (
              <PercentageDown>
                <i className="fa fa-arrow-down" />{' '}
                {Math.abs(data.conversion.percent_change).toLocaleString()}%
              </PercentageDown>
            ))}
          {data.conversion.percent_change !== 0 && (
            <CompareText>vs previous period</CompareText>
          )}
        </UsersData>
      </Summary>
    );
  }
}

const Summary = styled.div`
  display: grid;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
  margin-top: 20px;
  background: ${props => props.theme.white};
  grid-template-columns: 25% 25% 25% 25%;
  border-radius: 12px;
`;
const UsersData = styled.div`
  padding: 16px 0px 8px 0px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-right: 1px solid #c9cbcf;
  width: 100%;
`;
const UsersNumber = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #296bde;
`;
const PercentageUp = styled.div`
  font-size: 16px;
  color: #1abc9c;
  font-weight: normal;
`;
const PercentageDown = styled.div`
  font-size: 16px;
  color: #ff7070;
  font-weight: normal;
`;
const CompareText = styled.div`
  font-size: 10px;
  color: #979eaa;
  font-weight: bold;
`;

export default SummaryBar;
