import React, { Component, Fragment } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import styled from 'styled-components';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import 'filepond/dist/filepond.min.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Tooltip from '@material-ui/core/Tooltip';
import { Input, ErrorMessage, Label } from 'merchant-portal-components';
import { ButtonRow, Button } from 'merchant-portal-components';
import { Modal } from 'merchant-portal-components';
import TextField from '@material-ui/core/TextField';
import { TooltipIcon } from '../../../components/Icon';
import ImageCropModal from './ImageCropModal';
import 'filepond/dist/filepond.min.css';

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageTransform,
  FilePondPluginImageResize,
  FilePondPluginFileRename,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageEdit
);

const SubHeading = styled.div`
  margin: 5px 0 18px 0;
`;

const SubTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
`;

const StyleCard = styled.div`
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
  background: white;
  font-size: 16px;
  padding: 24px;
  max-width: 800px;
`;

const WrapRow = styled.div`
  display: grid;
  grid-template-columns: 250px 400px;
  align-items: flex-start;
  grid-gap: 48px;
`;

const ProductImage = styled.img`
  height: 255px;
  width: 250px;
  object-fit: cover;
  border-radius: 6px;
  background-color: #d3d3d3;
`;

const WrapCol = styled.div`
  grid-row: 1;
  display: grid;
  align-items: flex-start;
`;
const UploadCol = styled.div`
  grid-row: 1/3;
  display: grid;
  border: ${(props) =>
    props.isEditing || (props.files && props.files.length > 0)
      ? 'none'
      : '2px dashed #2440ae'};
  border-radius: 6px;
  height: 245px;
`;

const FilePondWrapper = styled.div`
.filepond--root,
.filepond--root .filepond--drop-label {
  height: 255px;
  font-family: Montserrat;
}
.filepond--panel-root {
  background-color: #fff;
}

.filepond--label-action{
  background: #e6ebff;
  padding: 10px;
  text-decoration: none;
  border-radius: 8px;
  margin-top: 10px;
  font-weight: 600;
  font-family: Montserrat;
  color: #2440ae;
}
.filepond--panel-root {
  background-color: transparent;
  /* border: 2px dashed #2440ae; */
}

.filepond--file-action-button:click .filepond--panel-root  {
  border: none;
}
.fileInfo{
  margin-top: 20px;
}

.filepond--action-edit-item{
  background-color: #fff;
  border: 1px solid #2440AE !important;
  position: fixed !important;
  bottom: -50px !important;
  width: 30px !important;
  height: 30px !important;
  color: #2440AE !important;

  &:hover {
    background-color :#2440AE;
    color: #fff !important;
  }
}
}
`;

const required = (value) => (value ? undefined : 'Required');
export const altTextDescription =
  "Alt Text is a description of an image that gets displayed if the users device can't render the image or if a user is using a screen reading device to access your site. Alt text can also be leveraged for SEO purposes.";
class ImageForm extends Component {
  state = {
    isCropping: false,
  };

  render() {
    const {
      subTitle,
      subHeading,
      changeImageName,
      changeAltText,
      handleImageUploadChange,
      confirmReady,
      cancelForm,
      formValues,
      nameError,
      altError,
      onSubmit,
      submitIsDisabled,
      isEditing,
      submitButtonText,
      files,
      handleInit,
      src,
      crop,
      onImageLoaded,
      onCropComplete,
      onChangeToIncompleteCropClick,
      onCropChange,
      handleCropSubmit,
      handleCropCancel,
      handleBeforeRemoveFile,
      handleOnUpadateFiles,
      handleOnAddFiles,
      imageEditEditor,
    } = this.props;

    return (
      <Modal>
        <StyleCard>
          <SubTitle>{subTitle}</SubTitle>
          {/* <SubHeading>{subHeading}</SubHeading> */}
          <WrapRow>
            <UploadCol files={files} isEditing={isEditing}>
              {isEditing ? (
                <Fragment>
                  <ProductImage src={formValues.url} />
                  {/* <div onClick={() => this.setState({isCropping : true})}> Edit </div> */}
                  {this.state.isCropping && (
                    <ImageCropModal
                      src={src}
                      crop={crop}
                      onImageLoaded={onImageLoaded}
                      onCropComplete={onCropComplete}
                      onChangeToIncompleteCropClick={
                        onChangeToIncompleteCropClick
                      }
                      onCropChange={onCropChange}
                      handleCropSubmit={handleCropSubmit}
                      handleCropCancel={handleCropCancel}
                    />
                  )}
                </Fragment>
              ) : (
                <FilePondWrapper>
                  <FilePond
                    ref={(ref) => (this.pond = ref)}
                    files={files}
                    allowMultiple={false}
                    imageResizeTargetWidth="200"
                    imageResizeTargetHeight="200"
                    oninit={handleInit}
                    beforeRemoveFile={() => {
                      handleBeforeRemoveFile();
                      // this.setState({ src: null, croppedImageUrl: null });
                    }}
                    onupdatefiles={(fileItems) => {
                      // Set currently active file objects to this.state
                      handleOnUpadateFiles(fileItems);
                      handleImageUploadChange(fileItems);
                    }}
                    onaddfile={(error, file) => handleOnAddFiles(file)}
                    labelIdle='Drag & Drop Image <br> or <br> 
                              <div class="filepond--label-action">Browse Computer</div>
                              <div class="fileInfo"> Maximum File Size: 2MB <br> Format: .jpg, .png, .JPEG </div>
                            '
                    imageEditEditor={imageEditEditor}
                    imageEditIconEdit='
                  <svg aria-hidden="true" width="26" height="26" viewBox="0 0 26 26" currentColor="black" 
                      style="position: absolute"
                      xmlns="http://www.w3.org/2000/svg">
                      <i class="fas fa-crop-alt"></i>
                  </svg> '
                  />
                  {src && (
                    <ImageCropModal
                      src={src}
                      crop={crop}
                      onImageLoaded={onImageLoaded}
                      onCropComplete={onCropComplete}
                      onChangeToIncompleteCropClick={
                        onChangeToIncompleteCropClick
                      }
                      onCropChange={onCropChange}
                      handleCropSubmit={handleCropSubmit}
                      handleCropCancel={handleCropCancel}
                    />
                  )}
                </FilePondWrapper>
              )}
            </UploadCol>
            <WrapCol>
              <Label>Image Name*</Label>
              <TextField
                id={'image-name'}
                variant="outlined"
                style={{
                  marginBottom: '20px',
                  // height: '140px',
                  resize: 'none',
                }}
                value={formValues.name}
                onChange={changeImageName}
                required={required}
              />
              <ErrorMessage style={{ paddingBottom: '2px' }}>
                {nameError}
              </ErrorMessage>
              <Label style={{ marginTop: '7px' }}>
                Alt Text{' '}
                <Tooltip
                  style={{ marginRight: 'auto' }}
                  title={altTextDescription}
                >
                  <TooltipIcon />
                </Tooltip>
              </Label>

              <TextField
                variant="outlined"
                style={{
                  marginBottom: '20px',
                  // height: '140px',
                  resize: 'none',
                }}
                id={'alt-text'}
                value={formValues.altText}
                onChange={changeAltText}
                required={required}
              />
              <ErrorMessage style={{ paddingBottom: '2px' }}>
                {altError}
              </ErrorMessage>
            </WrapCol>
          </WrapRow>
          <ButtonRow
            style={{ marginTop: '20px' }}
            justifyContent="space-between"
          >
            <Button secondary onClick={cancelForm}>
              Cancel
            </Button>
            <Button disabled={submitIsDisabled} onClick={onSubmit}>
              {submitButtonText}
            </Button>
          </ButtonRow>
        </StyleCard>
      </Modal>
    );
  }
}

export default ImageForm;
