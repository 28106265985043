import styled from 'styled-components';

const SelectionRow = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 6px;
  border-bottom: ${props =>
    props.error ? '1px solid red' : `1px solid #a6a9aa`};
  cursor: pointer;
`;

export default SelectionRow;
