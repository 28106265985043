import React from 'react';
import styled from 'styled-components';
import { reduxForm, Field, getFormValues, Form } from 'redux-form';
import { connect } from 'react-redux';
import { renderTextField } from '../../../../../../../components/FormElements/ReduxForm';
import ToggleSwitch from '../../../../../../../components/ToggleSwitch/ToggleSwitch';
import {
  StyledP,
  FieldWrapper,
  ToggleWrapper,
} from '../../common/styledComponents';
import { Icon } from 'merchant-portal-components';
import Tooltip from '@material-ui/core/Tooltip';

const EditDeliveryZoneContainer = styled.div`
  padding: 35px;
`;

const EditDeliveryZoneFieldsGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.grid};
  grid-gap: 30px;
`;

const required = (value) => (value ? undefined : 'Required');

let EditDeliveryZoneForm = (props) => {
  const {
    formValues,
    initialValues,
    handleSubmit,
    handleEditDeliveryZoneFormSubmit,
  } = props;

  const isActive = formValues ? formValues.isActive : initialValues.isActive;

  return (
    <Form onSubmit={() => handleEditDeliveryZoneFormSubmit()}>
      <EditDeliveryZoneContainer>
        <StyledP padding="0 0 35px 0" semiBold size="18px">
          Edit a new Delivery Zone for this Location
        </StyledP>
        <EditDeliveryZoneFieldsGrid grid={'200px 200px 1fr'}>
          <FieldWrapper>
            <StyledP size="14px">Zone Name</StyledP>
            <Field
              name="zoneName"
              component={renderTextField}
              validate={required}
            />
          </FieldWrapper>
          <FieldWrapper>
            <StyledP size="14px">Delivery Fee for this Zone</StyledP>
            <Field
              name="zoneFee"
              component={renderTextField}
              type="text"
              validate={required}
              normalize={(value) => {
                if (/^-?[0-9]\d*\.?\d{0,2}$/.test(value) || value == '') {
                  return value;
                } else if (
                  value[0] == '$' &&
                  value.substring(1, value.length) == ''
                ) {
                  return value;
                } else if (
                  value[0] == '$' &&
                  /^-?[0-9]\d*\.?\d{0,2}$/.test(
                    value.substring(1, value.length)
                  )
                ) {
                  return value;
                }
              }}
            />
          </FieldWrapper>
          <FieldWrapper margin="27px 22px 0 300px">
            <ToggleWrapper>
              <ToggleSwitch name="isActive" />
              <StyledP align="center">
                {isActive ? 'Active' : 'Inactive'}
              </StyledP>
            </ToggleWrapper>
          </FieldWrapper>
        </EditDeliveryZoneFieldsGrid>
        <EditDeliveryZoneFieldsGrid grid={'300px 300px 1fr'}>
          <FieldWrapper>
            <StyledP size="14px">
              Discounted Delivery Threshold (Optional)
            </StyledP>
            <Field
              name="orderThreshold"
              component={renderTextField}
              type="text"
              normalize={(value) => {
                if (/^-?[0-9]\d*\.?\d{0,2}$/.test(value) || value == '') {
                  return value;
                } else if (
                  value[0] == '$' &&
                  value.substring(1, value.length) == ''
                ) {
                  return value;
                } else if (
                  value[0] == '$' &&
                  /^-?[0-9]\d*\.?\d{0,2}$/.test(
                    value.substring(1, value.length)
                  )
                ) {
                  return value;
                }
              }}
            />
          </FieldWrapper>
          <FieldWrapper>
            <StyledP size="14px">Discounted Delivery Fee (Optional)</StyledP>
            <Field
              name="orderThresholdFee"
              component={renderTextField}
              type="text"
              normalize={(value) => {
                if (/^-?[0-9]\d*\.?\d{0,2}$/.test(value) || value == '') {
                  return value;
                } else if (
                  value[0] == '$' &&
                  value.substring(1, value.length) == ''
                ) {
                  return value;
                } else if (
                  value[0] == '$' &&
                  /^-?[0-9]\d*\.?\d{0,2}$/.test(
                    value.substring(1, value.length)
                  )
                ) {
                  return value;
                }
              }}
            />
          </FieldWrapper>
          <FieldWrapper style={{ alignSelf: 'center' }}>
            <Tooltip
              placement="bottom"
              title="Orders with a subtotal greater than the Discounted Delivery Threshold will be charged the specified lower delivery fee."
            >
              <Icon className="far fa-info-circle" />
            </Tooltip>
          </FieldWrapper>
        </EditDeliveryZoneFieldsGrid>
      </EditDeliveryZoneContainer>
    </Form>
  );
};

EditDeliveryZoneForm = reduxForm({
  form: 'editDeliveryZoneForm',
  enableReinitialize: true,
  persistentSubmitErrors: true,
})(EditDeliveryZoneForm);

EditDeliveryZoneForm = connect((state) => {
  const { editingDeliveryZoneObject } = state.businessLocationDeliveryZones;
  return {
    initialValues: editingDeliveryZoneObject,
    formValues: getFormValues('editDeliveryZoneForm')(state),
  };
})(EditDeliveryZoneForm);

export default EditDeliveryZoneForm;
