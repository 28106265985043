import React from 'react';
import styled from 'styled-components';

const CalorieSpan = styled.span`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: ${props => '#1a191a'};
  align-self: flex-end;
`;

const CalorieString = ({calories}) => {
  const {min_calories, max_calories} = calories;

  if (min_calories === max_calories) {
    return <CalorieSpan>{min_calories} Cals</CalorieSpan>;
  } else {
    return (
      <CalorieSpan>
        {min_calories}-{max_calories} Cals
      </CalorieSpan>
    );
  }
};

export default CalorieString;
