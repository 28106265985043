import React from 'react';
import { connect } from 'react-redux';
import * as tagsActions from '../tagsActions';
import { reduxForm, Field, FieldArray } from 'redux-form';
import styled from 'styled-components';
import { ButtonRow, Button, FlatButton } from 'merchant-portal-components';
import {
  renderTextField,
  renderSelect,
  required,
  maxLength,
} from '../../../components/FormElements/ReduxForm';
import { FormGrid, FormRow } from 'merchant-portal-components';
import { Icon } from 'merchant-portal-components';

const Form = styled.form`
  background: ${(props) => props.theme.white};
  padding: 20px;
  max-height: 100%;
`;

const DetailWrapper = styled(FormGrid)`
  grid-gap: 0px 4px;
  overflow-y: ${(props) => (props.scroll ? 'scroll' : null)};
  max-height: 280px;
  margin: 0px 16px;
`;

const DetailRow = styled(FormRow)`
  grid-template-columns: 2fr 6fr 1fr;
  padding: 0px 12px;
  &:hover {
    background-color: ${(props) => props.theme.lightGray};
  }
`;

const HeaderRow = styled(FormRow)`
  position: sticky;
  top: 0;
  margin: 36px 0px 0px 0px;
  padding: 0px 12px;
  grid-template-columns: 2fr 6fr 1fr;
  margin-top: 12px;
`;

const RemoveIcon = styled(Icon)`
  text-align: right;
  align-self: center;
  margin: 16px 12px 16px 0px;
  font-size: 20px;
`;

const Title = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 24px;
`;

const maxLength50 = maxLength(50);

const renderDetails = ({ fields, meta: { error, submitFailed } }) => (
  <div>
    <ButtonRow justifyContent="space-between" style={{ marginBottom: '10px' }}>
      <div>{fields.length > 0 && 'Tag Details'}</div>
      <FlatButton type="button" onClick={() => fields.push({ value: '' })}>
        + Add Tag Detail
      </FlatButton>
    </ButtonRow>
    <FormGrid>
      <DetailWrapper scroll={fields.length > 3 ? true : false}>
        {fields.length > 0 && (
          <HeaderRow key="header">
            <div>
              <h4
                style={{
                  marginBottom: '5px',
                  fontWeight: '600',
                }}
              >
                Key*
              </h4>
            </div>
            <div>
              <h4
                style={{
                  marginBottom: '5px',
                  fontWeight: '600',
                }}
              >
                Value
              </h4>
            </div>
            <div />
          </HeaderRow>
        )}
        {fields.map((detail, index) => (
          <DetailRow key={index}>
            <Field
              marginTop={'12px'}
              name={`${detail}.key`}
              type="text"
              component={renderTextField}
              validate={required}
            />

            <Field
              marginTop={'12px'}
              name={`${detail}.value`}
              type="text"
              component={renderTextField}
              // validate={required}
            />
            <RemoveIcon
              className="fal fa-trash-alt"
              onClick={() => fields.remove(index)}
            />
          </DetailRow>
        ))}
      </DetailWrapper>
    </FormGrid>
  </div>
);

const tagTypeOptions = [
  {
    value: 'business',
    label: 'Business',
  },
  {
    value: 'user',
    label: 'User',
  },
  {
    value: 'feed',
    label: 'Feed',
  },
  {
    value: 'product',
    label: 'Product',
  },
];

let TagForm = ({
  isEditing,
  hideTagForm,
  handleSubmit,
  isRequesting,
  valid,
  ...props
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Title> {props.initialValues && props.initialValues.name}</Title>
      <FormGrid>
        <FormRow>
          <Field
            inLine
            name="type"
            component={renderSelect}
            label="Tag Type*"
            options={tagTypeOptions}
            simpleValue
            light
            topMod="-16px"
            whiteBackground
            validate={required}
            isDisabled={isEditing}
          />
          <div />
        </FormRow>
        <FormRow>
          <Field
            name="name"
            component={renderTextField}
            label="Tag Name*"
            type="text"
            validate={[required, maxLength50]}
          />
          <Field
            name="value"
            component={renderTextField}
            label="Tag Value"
            type="text"
            // validate={required}
          />
        </FormRow>

        <FieldArray name="detail" component={renderDetails} />
        <ButtonRow justifyContent="space-between">
          <Button onClick={hideTagForm} secondary type="button">
            Cancel
          </Button>
          <Button disabled={isRequesting ? isRequesting : !valid} type="submit">
            {isEditing ? 'Update Tag' : 'Create Tag'}
          </Button>
        </ButtonRow>
      </FormGrid>
    </Form>
  );
};

function mapStateToProps(state) {
  return {
    isEditing: state.tagsReducer.isEditing,
    tagToEdit: state.tagsReducer.tagToEdit,
  };
}

const validate = (values) => {
  const errors = {
    detail: [],
  };
  if (values.detail) {
    values.detail.forEach((singleDetail, index) => {
      const keyAlreadyExists = values.detail.some(
        ({ key }, keyIndex) => key === singleDetail.key && keyIndex !== index
      );
      if (keyAlreadyExists) {
        errors.detail[index] = { key: 'Key must be unique' };
      }
    });
  }
  return errors.detail.length ? errors : {};
};

TagForm = connect(mapStateToProps, tagsActions)(TagForm);

export default reduxForm({
  form: 'tagForm',
  validate,
})(TagForm);
