import {
  GET_LOCATION_TRANSACTIONS,
  GET_LOCATION_SALES,
  GET_LOCATION_REFUND,
  GET_LOCATION_NEW_USER_TRANSACTIONS,
  LOADING
} from './types';

const initialFormValues = {
  time_period: {
    interval_type: 'day',
    interval_value: 30
  }
};

export default function(
  state = {
    initialFormValues
  },
  action
) {
  switch (action.type) {
    case GET_LOCATION_TRANSACTIONS:
      return {
        ...state,
        transactionsData: action.payload.data,
        loading: true
      };
    case GET_LOCATION_SALES:
      return {...state, salesData: action.payload.data};
    case GET_LOCATION_REFUND:
      return {...state, refundData: action.payload.data};
    case GET_LOCATION_NEW_USER_TRANSACTIONS:
      return {...state, newUserTransactionsData: action.payload.data};
    case LOADING:
      return {...state, loading: false};
    default:
      return {...state};
  }
  return state;
}
