import React from 'react';
import styled from 'styled-components';
import Fade from '@material-ui/core/Fade';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${props => (props.isVisible ? '1201' : '1200')};
`;

const Wrapper2 = styled.div`
  position: fixed;
  background-color: white;
  width: 100%;
  height: 56px;
  border-bottom: solid 1px #d3d3d3;
`;

const Wrapper3 = styled.div`
  margin: 14px 400px;
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;
  grid-gap: 154px;

  @media (max-width: 1400px) {
    margin: 14px 250px;
  }
  @media (max-width: 1085px) {
    margin: 14px 150px;
  }
  @media (max-width: 888px) {
    margin: 14px 50px;
    grid-gap: 30px;
  }
  @media (max-width: 530px) {
    margin: 14px 40px;
    grid-gap: 20px;
  }
`;

const Title = styled.div`
  font-size: 15px;
  @media (max-width: 640px) {
    font-size: 13px;
  }
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-gap: 49px;
  grid-auto-flow: column;
`;

const DiscardButton = styled.button`
  border: none;
  padding: 4px 10px;
  font-size: 15px;
  cursor: pointer;
  @media (max-width: 640px) {
    font-size: 13px;
  }
`;

const SaveButton = styled.button`
  border: none;
  padding: 4px 10px;
  color: ${props => (props.disabled ? 'gray' : props.theme.blue)};
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  @media (max-width: 640px) {
    font-size: 13px;
  }
`;

const StickyButtons = ({
  isVisible,
  handleDiscard,
  handleSave,
  title,
  saveIsDisabled,
  discardIsDisabled
}) => {
  return (
    <Fade in={isVisible} timeout={{ enter: 400, exit: 1000 }}>
      <Wrapper isVisible={isVisible}>
        <Wrapper2>
          <Wrapper3>
            <Title>{title}</Title>
            <ButtonsWrapper>
              <DiscardButton
                disabled={discardIsDisabled}
                onClick={handleDiscard}
              >
                Discard
              </DiscardButton>
              <SaveButton disabled={saveIsDisabled} onClick={handleSave}>
                Save
              </SaveButton>
            </ButtonsWrapper>
          </Wrapper3>
        </Wrapper2>
      </Wrapper>
    </Fade>
  );
};

export default StickyButtons;
