import React, {Component} from 'react';
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import {
  NoData,
  ChartCard,
  TitleWrapper,
  Title,
  TitleSpan
} from '../../components/DashboardElements';
import styled from 'styled-components';
import MaterialUITooltip  from '@material-ui/core/Tooltip'; 
import { TooltipIcon } from '../../../../components/Icon';

const parseDomain = (
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
) => {
  return [
    0,
    Math.max.apply(null, [
      ...Sunday.map(entry => entry.value),
      ...Monday.map(entry => entry.value),
      ...Tuesday.map(entry => entry.value),
      ...Wednesday.map(entry => entry.value),
      ...Thursday.map(entry => entry.value),
      ...Friday.map(entry => entry.value),
      ...Saturday.map(entry => entry.value)
    ])
  ];
};

//const ThreeDimScatterChart = React.createClass({
export default class TransactionsPerHour extends Component {
  renderTooltip(props) {
    const {active, payload} = props;

    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <ChartToolTip>
          {/* <span>{data.hour}</span> : <br /> */}
          <span>Transactions: {data.value}</span>
        </ChartToolTip>
      );
    }

    return null;
  }

  render() {
    const {
      Sunday,
      Monday,
      Tuesday,
      Wednesday,
      Thursday,
      Friday,
      Saturday
    } = this.props.data;

    const sundayData = Sunday.map(data => {
      return {hour: data.hour, index: 1, value: data.value};
    });
    const mondayData = Monday.map(data => {
      return {hour: data.hour, index: 1, value: data.value};
    });
    const tuesdayData = Tuesday.map(data => {
      return {hour: data.hour, index: 1, value: data.value};
    });
    const wednesdayData = Wednesday.map(data => {
      return {hour: data.hour, index: 1, value: data.value};
    });
    const thursdayData = Thursday.map(data => {
      return {hour: data.hour, index: 1, value: data.value};
    });
    const fridayData = Friday.map(data => {
      return {hour: data.hour, index: 1, value: data.value};
    });
    const saturdayData = Saturday.map(data => {
      return {hour: data.hour, index: 1, value: data.value};
    });
    const domain = parseDomain(
      Sunday,
      Monday,
      Tuesday,
      Wednesday,
      Thursday,
      Friday,
      Saturday
    );
    const range = [0, 340];
    const rangeMax = Math.max(...Sunday.map(entry => entry.value));
    const rangeMin = Math.min(...Sunday.map(entry => entry.value));
    return (
    
      <ChartCard>
        <TitleWrapper>
          <Title>
            <TitleSpan>
              Transactions per Hour {' '}
              <MaterialUITooltip title="The total number of transactions per hour" placement="top" arrow>
                <span> <TooltipIcon/> </span>
              </MaterialUITooltip>
            </TitleSpan>
          </Title>
        </TitleWrapper>
        {/* {
        (!UserSignupsCurrent || UserSignupsCurrent.length == 0) &&
        <NoData>
          No Data available
        </NoData>
        } */}
        <ScatterChartStyles>
          <ResponsiveContainer>
            <ScatterChart
              width={600}
              height={60}
              margin={{top: 10, right: 0, bottom: 0, left: 0}}
            >
              <XAxis
                type="category"
                dataKey="hour"
                interval={0}
                tick={{fontSize: 0}}
                tickLine={{transform: 'translate(0, -6)'}}
              />
              <YAxis
                type="number"
                dataKey="index"
                height={10}
                width={80}
                tick={false}
                tickLine={false}
                axisLine={false}
                label={{value: 'Sunday', position: 'insideRight'}}
              />
              <ZAxis
                type="number"
                dataKey="value"
                domain={domain}
                range={range}
              />
              <Tooltip
                cursor={{strokeDasharray: '3 3'}}
                wrapperStyle={{zIndex: 100}}
                content={this.renderTooltip}
              />
              <Scatter data={sundayData.slice(6, 23)} fill="#3C6CD7" />
            </ScatterChart>
          </ResponsiveContainer>
        </ScatterChartStyles>

        <ScatterChartStyles>
          <ResponsiveContainer>
            <ScatterChart
              width={600}
              height={60}
              margin={{top: 10, right: 0, bottom: 0, left: 0}}
            >
              <XAxis
                type="category"
                dataKey="hour"
                name="hour"
                interval={0}
                tick={{fontSize: 0}}
                tickLine={{transform: 'translate(0, -6)'}}
              />
              <YAxis
                type="number"
                dataKey="index"
                height={10}
                width={80}
                tick={false}
                tickLine={false}
                axisLine={false}
                label={{value: 'Monday', position: 'insideRight'}}
              />
              <ZAxis
                type="number"
                dataKey="value"
                domain={domain}
                range={range}
              />
              <Tooltip
                cursor={{strokeDasharray: '3 3'}}
                wrapperStyle={{zIndex: 100}}
                content={this.renderTooltip}
              />
              <Scatter data={mondayData.slice(6, 23)} fill="#3C6CD7" />
            </ScatterChart>
          </ResponsiveContainer>
        </ScatterChartStyles>

        <ScatterChartStyles>
          <ResponsiveContainer>
            <ScatterChart
              width={600}
              height={60}
              margin={{top: 10, right: 0, bottom: 0, left: 0}}
            >
              <XAxis
                type="category"
                dataKey="hour"
                name="hour"
                interval={0}
                tick={{fontSize: 0}}
                tickLine={{transform: 'translate(0, -6)'}}
              />
              <YAxis
                type="number"
                dataKey="index"
                height={10}
                width={80}
                tick={false}
                tickLine={false}
                axisLine={false}
                label={{value: 'Tuesday', position: 'insideRight'}}
              />
              <ZAxis
                type="number"
                dataKey="value"
                domain={domain}
                range={range}
              />
              <Tooltip
                cursor={{strokeDasharray: '3 3'}}
                wrapperStyle={{zIndex: 100}}
                content={this.renderTooltip}
              />
              <Scatter data={tuesdayData.slice(6, 23)} fill="#3C6CD7" />
            </ScatterChart>
          </ResponsiveContainer>
        </ScatterChartStyles>

        <ScatterChartStyles>
          <ResponsiveContainer>
            <ScatterChart
              width={600}
              height={60}
              margin={{top: 10, right: 0, bottom: 0, left: 0}}
            >
              <XAxis
                type="category"
                dataKey="hour"
                name="hour"
                interval={0}
                tick={{fontSize: 0}}
                tickLine={{transform: 'translate(0, -6)'}}
              />
              <YAxis
                type="number"
                dataKey="index"
                height={10}
                width={80}
                tick={false}
                tickLine={false}
                axisLine={false}
                label={{value: 'Wednesday', position: 'insideRight'}}
              />
              <ZAxis
                type="number"
                dataKey="value"
                domain={domain}
                range={range}
              />
              <Tooltip
                cursor={{strokeDasharray: '3 3'}}
                wrapperStyle={{zIndex: 100}}
                content={this.renderTooltip}
              />
              <Scatter data={wednesdayData.slice(6, 23)} fill="#3C6CD7" />
            </ScatterChart>
          </ResponsiveContainer>
        </ScatterChartStyles>

        <ScatterChartStyles>
          <ResponsiveContainer>
            <ScatterChart
              width={600}
              height={60}
              margin={{top: 10, right: 0, bottom: 0, left: 0}}
            >
              <XAxis
                type="category"
                dataKey="hour"
                name="hour"
                interval={0}
                tick={{fontSize: 0}}
                tickLine={{transform: 'translate(0, -6)'}}
              />
              <YAxis
                type="number"
                dataKey="index"
                height={10}
                width={80}
                tick={false}
                tickLine={false}
                axisLine={false}
                label={{value: 'Thursday', position: 'insideRight'}}
              />
              <ZAxis
                type="number"
                dataKey="value"
                domain={domain}
                range={range}
              />
              <Tooltip
                cursor={{strokeDasharray: '3 3'}}
                wrapperStyle={{zIndex: 100}}
                content={this.renderTooltip}
              />
              <Scatter data={thursdayData.slice(6, 23)} fill="#3C6CD7" />
            </ScatterChart>
          </ResponsiveContainer>
        </ScatterChartStyles>

        <ScatterChartStyles>
          <ResponsiveContainer>
            <ScatterChart
              width={600}
              height={60}
              margin={{top: 10, right: 0, bottom: 0, left: 0}}
            >
              <XAxis
                type="category"
                dataKey="hour"
                name="hour"
                interval={0}
                tick={{fontSize: 0}}
                tickLine={{transform: 'translate(0, -6)'}}
              />
              <YAxis
                type="number"
                dataKey="index"
                height={10}
                width={80}
                tick={false}
                tickLine={false}
                axisLine={false}
                label={{value: 'Friday', position: 'insideRight'}}
              />
              <ZAxis
                type="number"
                dataKey="value"
                domain={domain}
                range={range}
              />
              <Tooltip
                cursor={{strokeDasharray: '1 1'}}
                wrapperStyle={{zIndex: 100}}
                content={this.renderTooltip}
              />
              <Scatter data={fridayData.slice(6, 23)} fill="#3C6CD7" />
            </ScatterChart>
          </ResponsiveContainer>
        </ScatterChartStyles>

        <ScatterChartStyles>
          <ResponsiveContainer>
            <ScatterChart
              width={600}
              height={60}
              margin={{top: 10, right: 0, bottom: 0, left: 0}}
            >
              <XAxis
                type="category"
                dataKey="hour"
                name="hour"
                interval={0}
                tickLine={{transform: 'translate(0, -6)'}}
              />
              <YAxis
                type="number"
                dataKey="index"
                height={10}
                width={80}
                tick={false}
                tickLine={false}
                axisLine={false}
                label={{value: 'Saturday', position: 'insideRight'}}
              />
              <ZAxis
                type="number"
                dataKey="value"
                domain={domain}
                range={range}
              />
              <Tooltip
                cursor={{strokeDasharray: '1 1'}}
                wrapperStyle={{zIndex: 100}}
                content={this.renderTooltip}
              />
              <Scatter data={saturdayData.slice(6, 23)} fill="#3C6CD7" />
            </ScatterChart>
          </ResponsiveContainer>
        </ScatterChartStyles>
      </ChartCard>
    );
  }
}

const ScatterChartStyles = styled.div`
  font-size: 10px;
  height: 55px;
  margin-left: -15px;
`;

const ChartToolTip = styled.div`
  background-color: #000;
  opacity: 0.8;
  /*border: '1px solid #999',*/
  color: ${props => props.theme.white};
  margin: 0;
  padding: 5px;
  height: 25px;
  border-radius: 5px;
  font-weight: normal;
  font-size: 12px;
`;
