import React from 'react';
import styled from 'styled-components';
import food from '../../../../../public/img/PriceManagement/food.png';

const Card = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 48px 51px;
  display: grid;
  grid-gap: 32px;
  align-items: center;
  text-align: center;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  width: ${props => (props.customWidth ? '1000px' : 'inherit')};

  @media (max-width: 1313px) {
    width: ${props => (props.customWidth ? '100%' : 'inherit')};
  }
`;

const NoOptionVariantsSelected = ({ text, customWidth }) => {
  return (
    <Card customWidth={customWidth}>
      <img src={food} style={{ margin: '0 auto' }} height="118" width="auto" />
      <div> {text} </div>
    </Card>
  );
};

export default NoOptionVariantsSelected;
