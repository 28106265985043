import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, {
  anchorRef,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state';
import { IconEllipsis } from '../styles/style';
import ComboItems from './ComboItems';

const ComboItemsPopUp = ({
  comboItemsLength,
  setIsSharedIncludedOptionsModal,
  setIsSortComboItemsModal,
}) => {
  return (
    <PopupState variant="popover" popupId="comboGroupMenu">
      {(popupState) => (
        <div>
          <div containerprops={{ ref: anchorRef(popupState) }}>
            <IconEllipsis
              className="fas fa-ellipsis-v"
              style={{ fontSize: '20px', marginLeft: '15px' }}
              onClick={(e) => {
                e.stopPropagation();
                setIsSharedIncludedOptionsModal(true);
              }}
              // {...bindTrigger(popupState)}
              disabled={!comboItemsLength}
            />
          </div>
          <Menu
            disableAutoFocusItem={true}
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <MenuItem
              onClick={() => {
                popupState.close();
                setIsSharedIncludedOptionsModal(true);
              }}
              style={{ fontSize: '14px' }}
            >
              <i className="fas fa-list" style={{ marginRight: '5px' }} />
              Shared Included Options
            </MenuItem>
            <MenuItem
              disabled={comboItemsLength == 1}
              onClick={() => {
                popupState.close();
                setIsSortComboItemsModal(true);
              }}
              style={{ fontSize: '14px' }}
            >
              <i
                className="far fa-sort-amount-down"
                style={{ marginRight: '5px' }}
              />
              Sort Combo Groups
            </MenuItem>
          </Menu>
        </div>
      )}
    </PopupState>
  );
};

export default ComboItemsPopUp;
