// format locations to show only children locations in case they exist
export const formatLocations = businesses => {
  let allBusinessesModified;
  allBusinessesModified = businesses.map(business => {
    if (business.sub_businesses.length) return business.sub_businesses.flat();
    return business;
  });
  allBusinessesModified = allBusinessesModified.flat();
  return allBusinessesModified;
};
