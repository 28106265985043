import {
  ADD_OVERLAY_ZONE_OBJECTS,
  REMOVE_OVERLAY_ZONE_OBJECT,
  ADD_OVERLAY_TO_STORE,
  UPDATE_DRAWN_OVERLAY_OBJECTS,
  UPDATE_EDIT_OVERLAY_OBJECT,
  RESET_REDUX_STATES
} from './types';

export function addOverlayZoneObjs(overlayZoneObj) {
  return function(dispatch) {
    dispatch({
      type: ADD_OVERLAY_ZONE_OBJECTS,
      payload: overlayZoneObj
    });
  };
}

export function removeOverlayZoneObjById(overlayZoneObjId) {
  return function(dispatch) {
    dispatch({
      type: REMOVE_OVERLAY_ZONE_OBJECT,
      payload: overlayZoneObjId
    });
  };
}

export function addOverlayToStore(overlayObj) {
  return function(dispatch) {
    dispatch({type: ADD_OVERLAY_TO_STORE, payload: overlayObj});
  };
}

export function updateDrawnOverlayObjects(overlayObjs) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_DRAWN_OVERLAY_OBJECTS,
      payload: overlayObjs
    });
  };
}

export function updateEditOverlayObject(overlayObj) {
  return function(dispatch) {
    dispatch({type: UPDATE_EDIT_OVERLAY_OBJECT, payload: overlayObj});
  };
}

export function resetReduxStates() {
  return function(dispatch) {
    dispatch({
      type: RESET_REDUX_STATES
    });
  };
}
