import {
  GET_DELIVERY_ZONE_OBJECTS_REQUEST,
  GET_DELIVERY_ZONE_OBJECTS_SUCCESS,
  DELETE_DELIVERY_ZONE_OBJECT_BY_ID_REQUEST,
  DELETE_DELIVERY_ZONE_OBJECT_BY_ID_SUCCESS,
  DELETE_DELIVERY_ZONE_OBJECT_BY_ID_FAILURE,
  UPDATE_DELIVERY_ZONE_OBJECT,
  UPDATE_EDITABLE_DELIVERY_ZONE,
  POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_REQUEST,
  POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_SUCCESS,
  POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_FAILURE,
  PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_REQUEST,
  PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_SUCCESS,
  PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_FAILURE,
  MAKE_DELIVERY_ZONE_NOT_EDITABLE_BY_ID,
  RESET_DELIVERYZONE_REDUCER_STATE
} from './types';

export default function(
  state = {
    deliveryZoneObjects: false,
    shouldComponentBeLoading: false,
    editingColour: null,
    editingDeliveryZoneObject: {},
    displayName: '',
    locationCoords: {}
  },
  action
) {
  switch (action.type) {
    case GET_DELIVERY_ZONE_OBJECTS_SUCCESS:
      return {
        ...state,
        deliveryZoneObjects: action.deliveryZones,
        editingColour: action.editingColour,
        shouldComponentBeLoading: false,
        locationCoords: action.locationCoords,
        displayName: action.displayName
      };
    case GET_DELIVERY_ZONE_OBJECTS_REQUEST:
      return {
        ...state,
        shouldComponentBeLoading: true,
        deliveryZoneObjects: [],
        editingColour: null,
        editingDeliveryZoneObject: {}
      };
    case DELETE_DELIVERY_ZONE_OBJECT_BY_ID_REQUEST:
      return {
        ...state,
        shouldComponentBeLoading: true
      };
    case DELETE_DELIVERY_ZONE_OBJECT_BY_ID_SUCCESS:
      return {
        ...state,
        shouldComponentBeLoading: false
      };
    case UPDATE_DELIVERY_ZONE_OBJECT:
      return {
        ...state,
        deliveryZoneObjects: state.deliveryZoneObjects.map(deliveryZone =>
          deliveryZone.id == action.payload.id ? action.payload : deliveryZone
        )
      };
    case UPDATE_EDITABLE_DELIVERY_ZONE:
      return {
        ...state,
        editingDeliveryZoneObject: action.payload
      };

    case POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_REQUEST:
      return {
        ...state,
        shouldComponentBeLoading: true
      };
    case POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_SUCCESS:
      return {
        ...state,
        shouldComponentBeLoading: false
      };
    case POST_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_FAILURE:
      return {
        ...state,
        shouldComponentBeLoading: false
      };
    case PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_REQUEST:
      return {
        ...state,
        shouldComponentBeLoading: true
      };
    case PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_SUCCESS:
      return {
        ...state,
        shouldComponentBeLoading: false
      };
    case PATCH_DELIVERY_ZONE_OBJECT_BY_LOCATION_ID_FAILURE:
      return {
        ...state,
        shouldComponentBeLoading: false
      };
    case MAKE_DELIVERY_ZONE_NOT_EDITABLE_BY_ID:
      return {
        ...state,
        deliveryZoneObjects: state.deliveryZoneObjects.map(deliveryZone =>
          deliveryZone.id == action.payload
            ? {...deliveryZone, isEditable: false}
            : deliveryZone
        )
      };
    case RESET_DELIVERYZONE_REDUCER_STATE:
      return {
        ...state
      }
    default:
      return state;
  }
}
