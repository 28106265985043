import React from 'react';
import styled from 'styled-components';
import {
  Input,
  ErrorMessage,
  StyledMultiselect,
  TextArea,
  TimePicker,
  Label,
  StyledNumberPicker,
  StyledDateTimePicker,
  SelectLabel,
} from 'merchant-portal-components';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import { Select } from 'merchant-portal-components';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Icon } from 'merchant-portal-components';

import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
//import { Icon } from '@material-ui/core';

const KeyboardDatePickerWrapper = styled(KeyboardDatePicker)`
    button {
    display: none;
  }
`;

Moment.locale('en');
momentLocalizer();

export const renderField = ({
  input,
  label,
  type,
  placeholder,
  light,
  min,
  disabled,
  meta: { touched, error, warning },
}) => (
  <div>
    {label && <Label>{label}</Label>}
    {touched && error ? (
      <Input
        {...input}
        placeholder={placeholder}
        type={type}
        style={{ border: '1px solid red' }}
        min={min}
        disabled={disabled}
      />
    ) : (
      <Input
        {...input}
        placeholder={placeholder}
        type={type}
        light={light}
        min={min}
        disabled={disabled}
      />
    )}
    <ErrorMessage> {touched && error} </ErrorMessage>
  </div>
);

const hideAutoFillColorStyle = {
  style: { WebkitBoxShadow: '0 0 0 1000px white inset' },
};

export const renderTextField = ({
  input,
  label,
  type,
  placeholder,
  light,
  min,
  required,
  disabled,
  icon,
  rows,
  password,
  marginTop,
  meta: { touched, error, warning },
}) => {
  return (
    <div>
      <TextField
        {...input}
        onChange={(e) => {
          if (disabled) {
            return null;
          } else if (input.onChange) {
            return input.onChange(e);
          }
        }}
        label={label}
        required={required}
        error={touched && error && error != ''}
        variant="outlined"
        inputProps={hideAutoFillColorStyle}
        type={password ? 'password' : 'text'}
        style={{
          width: '100%',
          hideAutoFillColorStyle,
          marginTop: marginTop && marginTop,
          backgroundColor: 'white',
        }}
        InputProps={
          icon
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon className={icon} />
                  </InputAdornment>
                ),
              }
            : null
        }
        multiline={rows ? true : false} // it strictly expects a boolean. rows is int
        rows={rows || 1}
      />

      <ErrorMessage style={{ marginBottom: '12px' }}>
        {touched && error}
      </ErrorMessage>
    </div>
  );
};

export const renderPriceField = ({
  input,
  label,
  type,
  placeholder,
  light,
  min,
  meta: { touched, error, warning },
}) => (
  <div>
    {label && <Label>{label}</Label>}
    {touched && error ? (
      <Input
        {...input}
        placeholder={placeholder}
        type={type}
        className="error_required"
        style={{ border: '1px solid red' }}
        min={min}
      />
    ) : (
      <Input
        {...input}
        placeholder={placeholder}
        type={type}
        light={light}
        min={min}
      />
    )}
    <ErrorMessage> {touched && error} </ErrorMessage>
  </div>
);

export const renderDateTimePicker = ({
  input: { onChange, value, onClick, onBlur },
  hideTime,
  hideDate,
  min,
  max,
  noDefault,
  noMinTime,
  meta: { touched, error },
}) => {
  if (value) {
    value = new Date(Moment(value));
  } else if (!noDefault) {
    value = new Date(Moment());
  } else {
    value = null;
  }

  min =
    min ||
    (!noMinTime
      ? new Date(Moment())
      : new Date(Moment('January 1, 1929 00:00:00')));

  return (
    <div>
      {touched && error ? (
        <StyledDateTimePicker
          value={value}
          onChange={(value) => {
            if (hideTime) {
              onChange(Moment(value).format('YYYY-MM-DD'));
            } else {
              onChange(Moment(value).format('YYYY-MM-DD HH:mm:ss'));
            }
          }}
          dropUp
          step={5}
          style={{ border: '1px solid red' }}
          time={!hideTime}
          date={!hideDate}
          inputProps={{
            component: (props) => <input {...props} readOnly />,
          }}
          style={{ border: '1px solid red' }}
          min={min}
        />
      ) : (
        <StyledDateTimePicker
          value={value}
          onChange={(value) => {
            if (hideTime) {
              onChange(Moment(value).format('YYYY-MM-DD'));
            } else {
              onChange(Moment(value).format('YYYY-MM-DD HH:mm:ss'));
            }
          }}
          defaultValue={value}
          dropUp
          step={5}
          time={!hideTime}
          date={!hideDate}
          inputProps={{
            component: (props) => <input {...props} readOnly />,
          }}
          min={min}
        />
      )}
      <ErrorMessage> {touched && error} </ErrorMessage>
    </div>
  );
};
export const renderDateTimeShow = ({
  input: { onChange, value, onClick, onBlur },
  hideTime,
  hideDate,
  min,
  max,
  noDefault,
  noMinTime,
  meta: { touch, error },
}) => {
  if (value) {
    value = new Date(Moment(value));
  } else if (!noDefault) {
    value = new Date(Moment());
  } else {
    value = null;
  }
  min =
    min ||
    (!noMinTime
      ? new Date(Moment())
      : new Date(Moment('January 1, 1969 00:00:00')));
  return (
    <div>
      {touch && error ? (
        <StyledDateTimePicker
          defaultValue={value}
          style={{ border: '1px solid red' }}
          min={min}
        />
      ) : (
        <StyledDateTimePicker defaultValue={value} min={min} />
      )}
    </div>
  );
};

export const renderTimePicker = ({
  input: { onChange, value, onClick, onBlur },
  label,
  hideDate,
  min,
  max,
  step,
  meta: { touched, error },
}) => {
  if (value) {
    value = new Date(value);
  } else {
    value = new Date();
  }
  return (
    <div>
      {label && <Label>{label}</Label>}
      {touched && error ? (
        <TimePicker
          //min={new Date()}
          onChange={onChange}
          dropUp
          step={step || 30}
          date={!hideDate}
          inputProps={{
            component: (props) => <input {...props} readOnly />,
          }}
          style={{ border: '1px solid red' }}
          min={min ? min : new Date('January 1, 1969 00:00:00')}
          max={max ? max : new Date('January 1, 3999 23:45:00')}
        />
      ) : (
        <TimePicker
          //min={new Date()}
          onChange={onChange}
          defaultValue={value}
          value={value}
          dropUp
          step={step || 15}
          date={!hideDate}
          inputProps={{
            component: (props) => <input {...props} readOnly />,
          }}
          min={min ? min : new Date('January 1, 1969 00:00:00')}
          max={max ? max : new Date('January 1, 3999 23:45:00')}
        />
      )}
      <ErrorMessage> {touched && error} </ErrorMessage>
    </div>
  );
};

const filterNumber = (label, input, howToChange, itemName) => {
  if (input.value == 0) {
    howToChange(input.name, label, itemName, label);
  } else {
    howToChange(input.name, 0, itemName, label);
  }
};

export const renderFilterCheckbox = ({
  itemName,
  input,
  label,
  readOnly,
  howToChange,
}) => {
  return (
    <Checkbox
      itemName={itemName}
      label={label}
      checked={input.value ? true : false}
      onChange={() => filterNumber(label, input, howToChange, itemName)}
      disabled={readOnly}
      color="primary"
    />
  );
};

export const renderCheckbox = ({ input, label, readOnly }) => {
  return (
    <Checkbox
      label={label}
      checked={input.value ? true : false}
      onChange={input.onChange}
      disabled={readOnly}
      color="primary"
    />
  );
};

export const renderRadioGroup = ({ input, ...rest }) => (
  <RadioGroup
    {...input}
    {...rest}
    value={input.value}
    onChange={(event) => input.onChange(event.target.value)}
  />
);

export const renderMultiselect = ({
  input,
  data,
  valueField,
  textField,
  label,
  meta: { touched, error },
}) => {
  return (
    <div>
      {label && <SelectLabel>{label}</SelectLabel>}
      {touched && error ? (
        <StyledMultiselect
          {...input}
          onBlur={() => input.onBlur()}
          value={input.value || null} // requires value to be an array
          data={data}
          valueField={valueField}
          textField={textField}
          errorText={touched && error}
          style={{ border: '1px solid red' }}
        />
      ) : (
        <StyledMultiselect
          {...input}
          onBlur={() => input.onBlur()}
          value={input.value || null} // requires value to be an array
          data={data}
          valueField={valueField}
          textField={textField}
          errorText={touched && error}
        />
      )}
      <ErrorMessage> {touched && error} </ErrorMessage>
    </div>
  );
};

export const renderTextArea = ({
  input,
  label,
  type,
  meta: { touched, error },
  ...rest
}) => {
  let variant = '';
  if (touched) {
    let variant = 'error_required';
  } else {
    let variant = 'nothing';
  }
  return (
    <div>
      {label && <Label>{label}</Label>}
      {touched && error ? (
        <TextArea
          {...input}
          {...rest}
          style={{
            resize: 'none',
            border: '1px solid red',
            color: `${(props) => props.theme.darkBlue}`,
            fontWeight: '600',
          }}
        />
      ) : (
        <TextArea
          {...input}
          {...rest}
          style={{
            resize: 'none',
            color: `${(props) => props.theme.darkBlue}`,
            fontWeight: '600',
          }}
        />
      )}
      <ErrorMessage> {touched && error} </ErrorMessage>
    </div>
  );
};

export const renderNumberPicker = ({
  input: { onChange, value },
  meta: { touched, error, warning },
}) => {
  value = parseInt(value);
  return (
    <div>
      {touched && error ? (
        <StyledNumberPicker onChange={onChange} defaultValue={value} />
      ) : (
        <StyledNumberPicker onChange={onChange} defaultValue={value} />
      )}
      <ErrorMessage> {touched && error} </ErrorMessage>
    </div>
  );
};

export const renderSelect = ({
  className,
  input,
  label,
  options,
  isMulti,
  simpleValue,
  getValueOnChange,
  valueKey,
  whiteBackground,
  disabled,
  inLine,
  zIndex,
  meta: { touched, error },
  addToStateProdFunc,
  ...rest
}) => {
  const { name, value, onBlur, onChange, onFocus } = input;
  function singleChangeHandler(func) {
    return function handleSingleChange(value) {
      let changeParam = '';
      if (value) {
        if (simpleValue) {
          changeParam = valueKey ? value[valueKey] : value.value;
        } else changeParam = value;
      }
      if (getValueOnChange) getValueOnChange(changeParam);
      func(changeParam);
    };
  }

  let allProdArray = [];
  function multiChangeHandler(func) {
    return function handleMultiHandler(values) {
      const getChangeParam = (value) => {
        let changeParam = value;

        if (simpleValue) {
          changeParam = valueKey ? value[valueKey] : value.value;
        }
        allProdArray.push(changeParam);
        if (addToStateProdFunc) addToStateProdFunc(allProdArray);
        return changeParam;
      };
      func(values.map((value) => getChangeParam(value)));
    };
  }

  function transformValue() {
    // if (isMulti) return value;
    if (isMulti) {
      if (simpleValue) {
        return options.filter((option) => {
          const identifier = valueKey ? option[valueKey] : option.value;
          return value.includes(identifier);
        });
      } else {
        return value;
      }
    }
    const getValueParam = (option) => {
      if (simpleValue) {
        if (valueKey) {
          return option[valueKey] == value;
        }
        return option.value == value;
      }
      return option == value;
    };
    return options.find((option) => getValueParam(option));
  }

  return (
    <Select
      whiteBackground={whiteBackground}
      touched={touched}
      name={name}
      onBlur={() => onBlur(value)}
      onFocus={onFocus}
      className={className}
      value={transformValue()}
      options={
        options && options.constructor === Array
          ? disabled
            ? []
            : options
          : []
      }
      isMulti={isMulti}
      inLine={inLine}
      label={label}
      error={error}
      zIndex={zIndex}
      onChange={
        disabled
          ? null
          : isMulti
          ? multiChangeHandler(onChange)
          : singleChangeHandler(onChange)
      }
      {...rest}
    />
  );
};

export const required = (value) =>
  value || value === 0 ? undefined : 'Required';

export const requiredSelect = (value) =>
  value.length === 0 ? 'Required' : undefined;

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const urlValidation = (value) => {
  let appIdentifier = localStorage.getItem('appIdentifier');
  let valueSubstr = value.indexOf(':');
  let sliceIdentifier = value.slice(0, valueSubstr);

  if (value && value.match(/^(ftp|http|https):\/\/[^ "]+$/)) {
    return undefined;
  } else if (sliceIdentifier == appIdentifier) {
    return undefined;
  } else {
    return 'Must be a valid URL';
  }
};
