import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from './actions';
import {Redirect} from 'react-router-dom';

class AccountSignout extends Component {
  componentWillMount() {
    this.props.signoutUser();
  }

  render() {
    return <Redirect to="/" />;
  }
}

function mapStateToProps(state) {
  return {errorMessage: state.account.error};
}

export default connect(
  null,
  actions
)(AccountSignout);
