import React, { Fragment } from "react";
import styled from "styled-components";

const BlackOverlay = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  padding: 10px;
  padding-bottom: 40px;
`;

const CardSubtitle = styled.div`
  font-size: 8px;
  font-weight: bold;
  color: ${props => (props.compact ? props.theme.black : props.theme.white)};
  margin-bottom: 4px;
  text-transform: uppercase;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-weight: bolder;
  color: ${props => (props.compact ? props.theme.black : props.theme.white)};
  line-height: ${props => (props.compact ? "16" : "20")}px;
`;

const SingleItemCard = ({ row, compact, id, preview }) => {
  const card = row.items[0];

  const ComponentContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
  `;

  const SingleCardWrapper = styled.div`
    flex-grow: 1;
    border-radius: ${props => props.theme.feedStyles.corner_radius}px;
    border-width: ${props => props.theme.feedStyles.border_width}px;
    border-style: solid;
    border-color: ${props =>
      props.theme.feedStyles.border_color
        ? props.theme.feedStyles.border_color
        : "transparent"};
    overflow: hidden;
    background-color: ${props => props.theme.white};
    background-image: url(${props => props.backgroundImage});
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  `;

  if (card) {
    return (
      <ComponentContainer>
        <SingleCardWrapper
          id={card.id}
          backgroundImage={!compact ? card.background_image_url : null}
          style={
            preview
              ? {
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center"
                }
              : null
          }
        >
          {compact ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 50px",
                gridGap: "12px",
                padding: "10px"
              }}
            >
              <div>
                <CardSubtitle compact>{card.subtitle}</CardSubtitle>
                <CardTitle compact>{card.title}</CardTitle>
              </div>
              <img src={card.background_image_url} style={{ width: "100%" }} />
            </div>
          ) : (
            <BlackOverlay>
              <CardSubtitle>
                {card.subtitle} {id}
              </CardSubtitle>
              <CardTitle>{card.title}</CardTitle>
            </BlackOverlay>
          )}
        </SingleCardWrapper>
      </ComponentContainer>
    );
  } else {
    return null;
  }
};

export default SingleItemCard;
