import React from "react";
import styled from "styled-components";
import color from "@material-ui/core/colors/grey";
import { Link } from "react-router-dom";
const Card = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 32px;
  background: white;
`;
const Heading = styled.div`
  display: inline;
  color: #191818;
`;
const HeadingLink = styled.div`
  display: inline;
  color: #2440ae;
`;
const SubHeading = styled.div`
  margin: 24px 0 0 0;
  font-weight: 600;
  color: #191818;
`;
const BusinessNames = styled.div`
  width: 50%;
  color: #191818;
  margin-top: 12px;
`;
const BusinessAssigned = props => {
  return (
    <Card>
      <Heading>To add and edit businesses assigned to this layout</Heading>
      <HeadingLink>
        <Link to="/layout-assignment">
          , go to the Layout Assignment Screen
        </Link>
      </HeadingLink>
      <SubHeading>Businesses assigned to this layout:</SubHeading>
      {props.listAppFeed.find(item => item.id === props.id).businesses
        .length !== 0 ? (
        props.listAppFeed
          .find(item => item.id === props.id)
          .businesses.map((item, index) => (
            <BusinessNames key={index}>{item.display_name}</BusinessNames>
          ))
      ) : (
        <BusinessNames>No Business Assigned</BusinessNames>
      )}
    </Card>
  );
};

export default BusinessAssigned;
