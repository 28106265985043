import {
  GET_SERVING_SIZE,
  GET_NUTRITION_FACTS,
  SORT_NUTRITION_FACTS,
  GET_NUTRITION_UNITS,
  GET_NUTRITION_PIVOT_DATA,
  GET_PRODUCT_SERVING_SIZE,
  PUT_PRODUCT_NUTRITION_VALUES
} from './nutritionTypes';

const initialState = {
  isRequestingGetServingSize: true,
  isRequestingGetNutritionFacts: true,
  isRequestingGetNutritionUnits: true,
  isRequestingGetProductServingSize: true,
  isRequestingGetNutritionPivotData: true,
  isRequestingGetNutritionPivotDataFiltered: true,
  productServingSize: null,
  servingSize: null,
  nutritionFactsList: [],
  nutritionUnitsList: [],
  nutritionPivotData: [],
  nutritionPivotDataFiltered: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SERVING_SIZE:
      return {
        ...state,
        isRequestingGetServingSize: false,
        servingSize: action.servingSize
      };
    case GET_NUTRITION_FACTS:
      return {
        ...state,
        isRequestingGetNutritionFacts: false,
        nutritionFactsList: action.nutritionFactsList
      };
    case SORT_NUTRITION_FACTS:
      return {
        ...state,
        nutritionFactsList: action.payload
      };
    case GET_NUTRITION_PIVOT_DATA:
      state.nutritionFactsList.forEach(fact => {
        let found = false;
        action.nutritionPivotDataFiltered.forEach(pivot => {
          if (pivot.nutrition_fact_id === fact.id) found = true;
        });

        if (!found) {
          action.nutritionPivotDataFiltered.push({
            is_featured: true,
            nutrition_fact_daily: '',
            nutrition_fact_id: fact.id,
            nutrition_fact_max_value: '',
            nutrition_fact_min_value: '',
            nutrition_fact_unit_id: '',
            product_id: action.productId,
            product_variant_id: action.productVariantId
          });
        }
      });
      return {
        ...state,
        isRequestingGetNutritionPivotData: false,
        isRequestingGetNutritionPivotDataFiltered: false,
        nutritionPivotData: action.nutritionPivotData,
        nutritionPivotDataFiltered: action.nutritionPivotDataFiltered
      };
    case GET_NUTRITION_UNITS:
      return {
        ...state,
        isRequestingGetNutritionUnits: false,
        nutritionUnitsList: action.nutritionUnitsList
      };
    case GET_PRODUCT_SERVING_SIZE:
      return {
        ...state,
        isRequestingGetProductServingSize: false,
        productServingSize: action.productServingSize
      };
    case PUT_PRODUCT_NUTRITION_VALUES:
      return {
        ...state,
        nutritionPivotData: action.nutritionPivotData,
        productServingSize: action.productServingSize
      };
    default:
      return state;
  }
}
