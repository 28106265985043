import React, { Component } from 'react';
import { ButtonRow, Button } from 'merchant-portal-components';
import { Card } from '../components/MenuManagementElements';
import { Modal } from 'merchant-portal-components';
import * as actions from '../../Media/mediaActions';
import { connect } from 'react-redux';
import { LoadingBlueLarge } from 'merchant-portal-components';
import Pagination from '../../../components/Pagination/Pagination';
import styled from 'styled-components';
import fetchOnParamChange from '../../HOCs/fetchOnParamChange';
import { DialogContent, DialogActions } from '@material-ui/core';

const ProductImage = styled.img`
  width: 450px;
  height: 300px;
`;

const BlackOverlay = styled.div`
  background: ${(props) => props.theme.white};
  padding: 10px;
  padding-bottom: 40px;
`;

const CardTitle = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.darkBlue};
  font-weight: bolder;
  line-height: ${(props) => (props.compact ? '16' : '20')}px;
`;

class MediaModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: null,
      selected: '',
      url: '',
    };
  }

  componentDidMount() {
    if (!this.props.mediaReducer.images) {
      this.props.getImages();
    }
  }

  renderFooter = () => {
    return (
      <ButtonRow justifyContent="center">
        <Button tertiary onClick={() => this.props.closeModal()}>
          Cancel
        </Button>
        <Button
          onClick={() => (
            this.props.closeModal(),
            this.props.selectProduct(this.state.selected, this.state.url)
          )}
        >
          Select
        </Button>
      </ButtonRow>
    );
  };

  renderImages = () => {
    if (
      this.props.mediaReducer.images &&
      this.props.mediaReducer.images.length
    ) {
      const { images } = this.props.mediaReducer;
      return images.map((image) => {
        return (
          <BlackOverlay
            key={image.media_id}
            style={
              this.state.selected == image.media_id
                ? {
                    border: `5px solid #108ee9`,
                    paddingBottom: '0px',
                  }
                : {
                    border: `5px solid white`,
                    paddingBottom: '0px',
                  }
            }
            onClick={() =>
              this.setState({ selected: image.media_id, url: image.url })
            }
          >
            <ProductImage
              style={{
                display: 'block',
                margin: 'auto',
                objectFit: 'contain',
                backgroundColor: '#d3d3d3',
              }}
              src={image.url}
            />
            <CardTitle>{image.name}</CardTitle>
          </BlackOverlay>
        );
      });
    } else {
      return (
        <div style={{ margin: 'auto' }}>
          <div style={{ fontSize: 30 }}>No Images uploaded into Gallery</div>
        </div>
      );
    }
  };

  render() {
    return (
      <Modal fullWidth maxWidth="sm">
        <DialogContent>
          {!this.props.mediaReducer.requestingGetImages ? (
            this.renderImages()
          ) : (
            <LoadingBlueLarge />
          )}
          {this.props.mediaReducer.images && this.props.mediaReducer.meta && (
            <Pagination
              response={{
                count: this.props.mediaReducer.meta.count,
                page_size: this.props.mediaReducer.meta.page_size,
                page_number: this.props.mediaReducer.meta.page_number,
              }}
              requestParams={{
                count: this.props.mediaReducer.meta.count,
                page_size: this.props.mediaReducer.meta.page_size,
                page_number: this.props.mediaReducer.meta.page_number,
              }}
              updateRequestParams={this.props.updateRequestParams}
            />
          )}
        </DialogContent>
        <DialogActions>{this.renderFooter()}</DialogActions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    mediaReducer: state.mediaReducer,
  };
}

const initialRequestParams = {
  page_number: 1,
  page_size: 10,
};

MediaModal = fetchOnParamChange(
  MediaModal,
  actions.getImages,
  initialRequestParams
);

MediaModal = connect(mapStateToProps, actions)(MediaModal);

export default MediaModal;
