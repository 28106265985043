export const GET_OFFERS_REQUEST = 'GET_OFFERS_REQUEST';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAILURE = 'GET_OFFERS_FAILURE';
export const SHOW_OFFER_INDEX = 'SHOW_OFFER_INDEX';
export const TOGGLE_OFFER_REQUEST = 'TOGGLE_OFFER_REQUEST';
export const TOGGLE_OFFER_SUCCESS = 'TOGGLE_OFFER_SUCCESS';
export const TOGGLE_OFFER_FAILURE = 'TOGGLE_OFFER_FAILURE';
export const INITIATE_CREATE_OFFER = 'INITIATE_CREATE_OFFER';
export const CREATE_OFFER_REQUEST = 'CREATE_OFFER_REQUEST';
export const CREATE_OFFER_SUCCESS = 'CREATE_OFFER_SUCCESS';
export const INITIATE_EDIT_OFFER = 'INITIATE_EDIT_OFFER';
export const SEARCH_CUSTOMER_REQUEST = 'SEARCH_CUSTOMER_REQUEST';
export const SEARCH_CUSTOMER_SUCCESS = 'SEARCH_CUSTOMER_SUCCESS';
export const CLEAR_CUSTOMER_SEARCH = 'CLEAR_CUSTOMER_SEARCH';
export const GET_CUSTOMER_DETAIL_REQUEST = 'GET_CUSTOMER_DETAIL_REQUEST';
export const CANCEL_CREATE_OFFER = 'CANCEL_CREATE_OFFER';
export const CREATE_OFFER_FAILURE = 'CREATE_OFFER_FAILURE';

export const EDIT_OFFER_REQUEST = 'EDIT_OFFER_REQUEST';
export const EDIT_OFFER_SUCCESS = 'EDIT_OFFER_SUCCESS';
export const EDIT_OFFER_FAILURE = 'EDIT_OFFER_FAILURE';

export const TOGGLE_OFFER_AVAILABILITY_SUCCESS =
  'TOGGLE_OFFER_AVAILABILITY_SUCCESS';

export const RESET_OFFER_STATE = 'RESET_OFFER_STATE';
export const UPDATE_SORTED_OFFERS_LIST = 'UPDATE_SORTED_OFFERS_LIST';
