import React, { Component } from 'react';
import Chart from 'chart.js';
import { Line } from 'react-chartjs-2';
import {
  NoData,
  ChartCard,
  TitleWrapper,
  Title,
  TotalWrapper,
  TitleSpan
} from '../../components/DashboardElements';
import { findMaxValue, findStepSize } from '../../utils/chart';
import Moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import {TooltipIcon} from '../../../../components/Icon';

class CumulativeUserSignUps extends Component {
  render() {
    const { cumulativeUsersData } = this.props;
    const userDates = cumulativeUsersData.dates;
    const users = cumulativeUsersData.counts;
    const total = cumulativeUsersData.total;
    var suggestedMax = findMaxValue(users);

    //find stepSize
    var stepSize = findStepSize(users);
    const data = canvas => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 1400, 0);
      gradient.addColorStop(0, '#3d50df');
      gradient.addColorStop(1, '#00a5db');
      return {
        labels: userDates,
        datasets: [
          {
            label: 'current period',
            fill: true,
            lineTension: 0,
            backgroundColor: gradient, //'rgba(75,192,192,0.4)',
            //borderColor: 'rgba(75,192,192,1)',
            //borderColor: '#296bde',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            //pointBorderColor: 'rgba(75,192,192,1)',
            //pointBorderColor: '#296bde',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            //pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBackgroundColor: '#296BDE',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            fontWeight: 'bold',
            borderWidth: 1,
            data: users
          }
        ]
      };
    };
    const options = {
      // Boolean - If we should show the scale at all
      showScale: false,
      // Boolean - Whether to show a dot for each point
      pointDot: false,
      // Boolean - Whether to show a stroke for datasets
      datasetStroke: false,
      legend: {
        display: false
      },
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              suggestedMin: 0,
              beginAtZero: true
              //stepSize: 200,
            },
            scaleLabel: {
              display: false
              //labelString: '# Of Sign Ups',
            }
          }
        ],
        xAxes: [
          {
            display: true,
            scaleLabel: {
              display: false
              //labelString: 'Date',
            },
            ticks: {
              callback: function(value) {
                const month = Moment(value).format('MMM'); //new Date(value).toLocaleDateString('en-EN', { day:'numeric'});
                //const month = new Date(value).toLocaleDateString('en-EN', { month:'short'});
                //const str= `${day}\n${month}`;
                return month;
              }
            }
          }
        ]
      }
    };

    return (
      <ChartCard>
        <TitleWrapper>
          <Title>
            <TitleSpan>
              Cumulative User Sign Ups {' '}
              <Tooltip title="The cumulative number of users who have accessed the app" placement="top" arrow>
                <span><TooltipIcon/></span>
              </Tooltip>
            </TitleSpan>
          </Title>
          <TotalWrapper>
            <span> Total </span>
            <TitleSpan> {(total || 0).toLocaleString()} </TitleSpan>
          </TotalWrapper>
        </TitleWrapper>
        <Line
          data={data}
          options={options}
          height={75}
          //width={700}
        />
      </ChartCard>
    );
  }
}

export default CumulativeUserSignUps;
