import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from 'merchant-portal-components';
import { Icon } from 'merchant-portal-components';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { setSearchResult } from '../../appFeedLayoutActions';
import { Select } from 'merchant-portal-components';

const Wrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 24px;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-gap: 24px;
  align-items: center;
  margin-bottom: 24px;
`;

const SortWrapper = styled.div`
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 20px auto 20px;
  align-items: center;
`;

const SortBySelect = styled.div`
  font-size: 12px;
  letter-spacing: -0.02px;
  color: #191818;
`;

const SearchCard = ({
  initiateCreateLayout,
  list,
  setSearchResult,
  search,
}) => {
  const listAppFeed = list.listAppFeed;

  const sortByOptions = [
    { value: 'name', label: 'Name' },
    { value: 'date-added', label: 'Date Added' },
    { value: 'date-modified', label: 'Date Modified' },
    { value: 'number-businesses', label: 'Number of Businesses Assigned' },
    { value: 'default-layout-first', label: 'Show default layout first' },
  ];

  const handleSearch = (e) => {
    setSearchResult(
      e.target.value,
      search.filterAttribute,
      search.filterOrder,
      listAppFeed
    );
  };

  const handleFilter = (attribute) => {
    setSearchResult(search.text, attribute, search.filterOrder, listAppFeed);
  };

  const renderSortOrderItem = () => {
    if (!search.filterOrder) return <i className="fa fa-sort"></i>;

    if (search.filterOrder === 'asc') return <i className="fa fa-sort-up"></i>;
    if (search.filterOrder === 'desc')
      return <i className="fa fa-sort-down"></i>;
  };

  const onChangeSortOrder = () => {
    let order = '';
    if (!search.filterOrder || search.filterOrder === 'desc') order = 'asc';
    else if (search.filterOrder === 'asc') order = 'desc';

    setSearchResult(search.text, search.filterAttribute, order, listAppFeed);
  };

  return (
    <Wrapper>
      <SortWrapper>
        <i className="fa fa-sort-amount-down"></i>
        <SortBySelect>
          <Select
            pagination
            small
            placeholder="Sort By"
            options={sortByOptions}
            onChange={(option) => handleFilter(option.value)}
          />
        </SortBySelect>
        <div onClick={onChangeSortOrder}>{renderSortOrderItem()}</div>
      </SortWrapper>
      <div>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon className="fal fa-search" />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          style={{ width: '100%' }}
          type="text"
          onChange={(e) => handleSearch(e)}
          placeholder="Search by template name"
          value={search.text}
        />
      </div>
      <div>
        <Button onClick={initiateCreateLayout}>New Layout</Button>
      </div>
    </Wrapper>
  );
};

export default SearchCard;
