export const getLifetimeCreditsBalance = customerGlobalCredits => {
  let totalLifeTimeCreditsBalance = 0;

  customerGlobalCredits.forEach(credit => {
    totalLifeTimeCreditsBalance += credit.initial_amount;
  });

  return totalLifeTimeCreditsBalance;
};

export default getLifetimeCreditsBalance;
