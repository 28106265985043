import styled from "styled-components";

export const PageWrapper = styled.div`
  height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

export const StyledForm = styled.form`
  display: grid;
  grid-gap: 15px;
`;

export const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: stretch;
  grid-gap: 1px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const Section = styled.section`
  max-width: 440px;
  background: white;
  padding: 20px;
  @media (max-width: 767px) {
    display: ${props => (props.hideOnSmall ? 'none' : 'inherit')};
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10%;
  height; 50%;
  align-items: flex-start;
`;