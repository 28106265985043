import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  setSearchValue,
  getItemsMappingFields,
  getItemsPOSValues,
  submitPOSValuesItems,
  updateItemsRequestParams,
} from '../posAction';
import { getOptionPrices } from '../../PriceManager/priceActions';
import { LoadingBlueLarge } from 'merchant-portal-components';
import ItemsMappingTable from './components/ItemsMappingTable';

const Wrapper = styled.div`
  width: 1000px;
  background: white;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  margin-top: 24px;

  @media (max-width: 1313px) {
    width: 100%;
  }
`;

let ItemsMappingContainer = ({
  getItemsMappingFields,
  itemsMappingFields,
  items,
  isRequestingItemsPosValues,
  isRequestingGetItemsMappingFields,
  selectedOption,
  getItemsPOSValues,
  variants,
  requestParams,
  optionPrices,
  getOptionPrices,
  submitPOSValuesItems,
  updateItemsRequestParams,
}) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getItemsMappingFields();
    getOptionPrices(selectedOption);
    getItemsPOSValues({ selectedOption, variants, optionPrices });
  }, []);

  useEffect(() => {
    if (optionPrices)
      getItemsPOSValues({
        selectedOption,
        variants,
        optionPrices,
      });
    setSearchValue('');
  }, [optionPrices]);

  useEffect(() => {
    getOptionPrices(selectedOption);
  }, [selectedOption.id]);

  const handleItemsSearch = (searchValue) => {
    updateItemsRequestParams({
      page_number: 1,
      page_size: requestParams.page_size,
    });
    setSearchValue(searchValue);
  };

  const sliceItems = (items) => {
    const indexOfFirst =
      (requestParams.page_number - 1) * requestParams.page_size;
    const indexOfLast = requestParams.page_number * requestParams.page_size;

    return items
      .filter(
        (item) =>
          item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
      )
      .slice(indexOfFirst, indexOfLast);
  };

  const [itemsActiveList, setItemsActiveList] = useState(sliceItems(items));

  useEffect(() => {
    setItemsActiveList(sliceItems(items));
  }, [requestParams, items]);

  const allItemsLength = items.filter(
    (item) => item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  ).length;

  return (
    <Wrapper>
      {isRequestingItemsPosValues ||
      isRequestingGetItemsMappingFields ||
      !optionPrices ||
      !items ? (
        <LoadingBlueLarge />
      ) : (
        <ItemsMappingTable
          requestParams={requestParams}
          setItemsActiveList={setItemsActiveList}
          submitPOSValuesItems={submitPOSValuesItems}
          itemsMappingFields={itemsMappingFields}
          handleItemsSearch={handleItemsSearch}
          allItemsLength={allItemsLength}
          items={itemsActiveList}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          getItemsPOSValues={getItemsPOSValues}
          selectedOption={selectedOption}
          optionPrices={optionPrices}
          variants={variants}
        />
      )}
    </Wrapper>
  );
};

// const initialRequestParams = {
//   page_number: 1,
//   page_size: 1000,
//   searchString: ''
// };

// ItemsMappingContainer = fetchOnParamChange(
//   ItemsMappingContainer,
//   getItemsPOSValues,
//   initialRequestParams
// );

const mapStateToProps = (state) => ({
  isRequestingGetItemsMappingFields:
    state.posReducer.isRequestingGetItemsMappingFields,
  itemsMappingFields: state.posReducer.itemsMappingFields,
  //searchValue: state.posReducer.searchValue,
  optionPrices: state.priceReducer.optionPrices,
  requestParams: state.posReducer.requestParams,
  isRequestingItemsPosValues: state.posReducer.isRequestingItemsPosValues,
});

export default connect(mapStateToProps, {
  getItemsMappingFields,
  //setSearchValue,
  getItemsPOSValues,
  getOptionPrices,
  submitPOSValuesItems,
  updateItemsRequestParams,
})(ItemsMappingContainer);
