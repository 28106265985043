import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { postNewUser } from './newUserLoginAction';
import NewUserLogin from './components/NewUserLogin';

const NewUserLoginContainer = props => {
  useEffect(() => {
    if (props.isUserRegisteredSuccessfully) {
      props.history.push('/login');
    }
  }, [props.isUserRegisteredSuccessfully]);

  return (
    <NewUserLogin
      postNewUser={props.postNewUser}
      params={props.match.params}
      history={props.history}
    />
  );
};

const mapStateToProps = state => ({
  newUserLoginReducer: state.newUserLoginReducer,
  isUserRegisteredSuccessfully: state.rolesReducer.isUserRegisteredSuccessfully
});

export default connect(mapStateToProps, { postNewUser })(NewUserLoginContainer);
