import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import BuildYourAudienceForm from './components/BuildYourAudienceForm';

const validate = values => {
  const errors = {};
  const requiredFields = ['name'];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  return errors;
};

class BuildYourAudience extends Component {
  render() {
    return (
      <BuildYourAudienceForm
        {...this.props}
        drawnOverlayZones={this.props.drawnOverlayZones}
      />
    );
  }
}

const selector = formValueSelector('segmentForm');
BuildYourAudience = connect(state => {
  const userGroupValue = selector(state, 'user_group');
  const audienceZoneValue = selector(state, 'audience.zone');
  return {
    userGroupValue,
    audienceZoneValue
  };
})(BuildYourAudience);

// Decorate with redux-form
BuildYourAudience = reduxForm({
  form: 'segmentForm',
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(BuildYourAudience);

export default BuildYourAudience;
