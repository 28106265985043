import styled from 'styled-components';

export const PrimaryButton = styled.button`
  height: 60px;
  min-width: 280px;
  color: ${props => '#fff'};
  background: ${props => (props.disabled ? 'gray' : '#2440ae')};
  text-transform: ${props => 'uppercase'};
  border-radius: ${props => '2px'};
  box-shadow: ${props => '0 4px 4px 0 rgba(0,0,0,0.2)'};
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: ${props => props.justifyContent || 'space-between'};
  @media (max-width: 768px) {
    grid-auto-flow: row;
    grid-gap: 20px;
    justify-content: stretch;
  }
`;
