export const formatDataSummaryView = (singleItemOverrides, priceKey) => {
  const groupedByVariantWithProps = {};

  singleItemOverrides.forEach(override => {
    // key format variantId_price_active_available
    let key;
    if (priceKey === 'additional_price')
      key = `${override.product_variant_id}_${override.additional_price}_${override.is_active}_${override.is_available}`;
    else
      key = `${override.product_variant_id}_${override.price}_${override.is_active}_${override.is_available}`;

    if (groupedByVariantWithProps[key]) {
      groupedByVariantWithProps[key].businesses.push(override.business_id);
    } else {
      groupedByVariantWithProps[key] = {
        product_variant_id: override.product_variant_id,
        price: override.price,
        is_active: override.is_active,
        is_available: override.is_available,
        additional_price: override.additional_price,
        businesses: [override.business_id]
      };
    }
  });

  const businessGroups = {};
  Object.values(groupedByVariantWithProps).forEach(variant => {
    // sort business arrays.
    variant.businesses = variant.businesses.sort();

    const key = variant.businesses.reduce((acc, id) => {
      acc += `_${id}`;
      return acc;
    }, '');

    const { businesses: variantBusinesses, ...variantOptions } = variant;
    if (businessGroups[key]) {
      businessGroups[key].item.prices.push({ ...variantOptions });
    } else {
      businessGroups[key] = {
        businesses: variantBusinesses,
        item: { prices: [{ ...variantOptions }] }
      };
    }
  });

  return businessGroups;
};

export const finalStructureSummaryData = (
  singleItemOverrides,
  item,
  formattedBusinesses,
  priceKey
) => {
  const response = formatDataSummaryView(singleItemOverrides, priceKey);

  let finalDataStructure = [];
  if (formattedBusinesses) {
    const formattedBusinessesModified = formattedBusinesses.reduce(
      (acc, val) => {
        acc[val.id] = val.display_name;
        return acc;
      },
      {}
    );

    const variantNameMap = item.prices.reduce((acc, val) => {
      acc[val.variant_id] = val.variantName;
      return acc;
    }, {});

    const variantIsSelectedMap = item.prices.reduce((acc, val) => {
      acc[val.variant_id] = val.isSelected;
      return acc;
    }, {});

    let responseArray = Object.values(response);

    finalDataStructure = responseArray.map(val => ({
      ...val,
      businesses: val.businesses.map(businessId => ({
        id: businessId,
        name: formattedBusinessesModified[businessId]
      })),
      item: {
        prices: val.item.prices.map(priceObj => ({
          ...priceObj,
          name: variantNameMap[priceObj.product_variant_id],
          isSelected: variantIsSelectedMap[priceObj.product_variant_id]
        }))
      }
    }));
  }

  return finalDataStructure;
};
